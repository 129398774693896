const FooterSubscribe = () =>{
    return (
        <div className="footer-subscribe">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="subscribe-content">
                                <h6 className="entry-subtitle">$20 discount for your first order</h6>
                                <h3 className="entry-title">Join our newsletter and get...</h3>
                                <div className="entry-teaser">
                                    <p>Join our email subscription now to get updates on promotions and coupons.</p>
                                </div>
                                <div className="form-wrapper">
                                   {/*  <script>(function () {
                                        window.mc4wp = window.mc4wp || {
                                            listeners: [],
                                            forms: {
                                                on: function (evt, cb) {
                                                    window.mc4wp.listeners.push(
                                                        {
                                                            event: evt,
                                                            callback: cb
                                                        }
                                                    );
                                                }
                                            }
                                        }
                                    })();
                                    </script> */}

                                    <form onSubmit={(e) => e.preventDefault()} className="mc4wp-form mc4wp-form-635" 
                                        data-name="Bacola">
                                        <div className="mc4wp-form-fields"> <i className="klbth-icon-mail"></i>
                                            <input  onChange={()=>{}} className="subscribe-input" type="email" name="EMAIL" placeholder="Your email address"
                                                required="" />

                                            <input  onChange={()=>{}} type="submit" value="Subscribe" />
                                        </div>
                                        <label style={{display: 'none'}}>Leave this field empty if you're human: 
                                        <input  onChange={()=>{}}
                                            type="text" name="_mc4wp_honeypot" value="" tabIndex="-1"
                                            autoComplete="off" />
                                        </label>
                                        <div className="mc4wp-response"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="subscribe-image"><img src="/images/coupon.png" alt="subscribe" /></div>
                        </div>
                    </div>
                </div>
            </div>        
    );
}


export default FooterSubscribe;