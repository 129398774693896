import { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../utils/AppProvider";
import Category from "../Category";

const HeaderMobileNavbar = () => {
    const { setSearch, setCartQty, cart_qty, is_authenticated, setShowMobileSearch, show_mobile_search } = useContext(AppContext);
    useEffect(()=>{
        // console.log("show_mobile_search",show_mobile_search);
    },[show_mobile_search]);
    return (
        <nav className="header-mobile-nav">
            <div className="mobile-nav-wrapper">
                <ul>
                    <li className="menu-item">
                        <Link to="/" className="store">
                            <i className="klbth-icon-store"></i>
                            <span>Store</span>
                        </Link>
                    </li>


                    <li className="menu-item">
                        <Link href="" onClick={() => { console.log("Clicked"); setShowMobileSearch(true)}} className="search">
                            <i className="klbth-icon-search"></i>
                            <span>Search</span>
                        </Link>
                    </li>

                    <li className="menu-item">
                        <Link to="/wishlist" className="wishlist">
                            <i className="klbth-icon-heart-1"></i>
                            <span>Wishlist</span>
                        </Link>
                    </li>

                    
                    {
                        is_authenticated ?
                        <li className="menu-item">
                            <Link to="/my-account" className="user">
                                <i className="klbth-icon-user"></i>
                                <span>Account</span>
                            </Link>
                        </li>
                        :
                        <li className="menu-item">
                            <Link to="/login" className="customer-sign">
                                {/* <i className="klbth-icon-user"></i> */}
                                <button className="customer-sign">Sign In</button>    
                                {/* <span>Sign In</span> */}
                            </Link>
                        </li>
                    }
                    

                    {/* <li className="menu-item">
                        <a href="#/home-3/#" className="categories">
                            <i className="klbth-icon-menu-thin"></i>
                            <span>Categories</span>
                        </a>
                    </li> */}

                </ul>
            </div>
        </nav>
    );
}

export default HeaderMobileNavbar;