import { Link } from "react-router-dom";

const CanvasHeader = (props) =>{
    return (
        <div className="canvas-header">
            <div className="site-brand">
               <Link to="/" title="B2B">
                  <img className="img-fluid" src="/images/kw-logo.png" alt="B2B" />

               </Link>
            </div>
            <div onClick={() => props.setMenu(!props.showMenu)} className="close-canvas">
               <i className="klbth-icon-x"></i>
            </div>
         </div>
    );
}

export default CanvasHeader;