import Category from "../Category";
import CanvasFooter from "./CanvasFooter";
import CanvasHeader from "./CanvasHeader";
import Location from "./Location";
import Navbar from "./Navbar";

const Canvas = (props) =>{
    return(
        <div className="site-canvas" style={{opacity:1,visibility:'inherit',transform:`translate(${props.showMenu  ? '0px':'-380px'},0px)`}}>
            <div className="site-scroll ps ps--active-y">
               <CanvasHeader showMenu={props.showMenu} setMenu={(val) => props.setMenu(val)} />

               <div className="canvas-main">
                  {/* <Location /> */}

                  <div className="canvas-title">
                     <h6 className="entry-title">Site Navigation</h6>
                  </div>
                  <Category />
                  <Navbar />
               </div>

               <CanvasFooter />
               <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                  <div className="ps__thumb-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div>
               </div>
               <div className="ps__rail-y" style={{ top: '0px', height: '530px', right: '0px' }}>
                  <div className="ps__thumb-y" tabIndex="0" style={{ top: '0px', height: '311px' }}></div>
               </div>
            </div>
         </div>
    );
}

export default Canvas;