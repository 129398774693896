import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveCategories = async(limit = 'all') => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/categories?limit=${limit}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveSingleCategory = async(subcat) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/single-category?category=${subcat}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveBrands = async() => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/brands`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { retriveCategories, retriveSingleCategory, retriveBrands };