import { useContext } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { login } from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";

const Login = () => {
    const [state, setState] = useState({
        email:'',
        password:''
    });
    const { setIsAuthenticated } = useContext(AppContext);

    const navigate = useNavigate();
    const [passwordType,setpasswordType] = useState(false);
    const handleInputChange =(e)=>{
        let {name, value} = e.target;
        setState({...state, [name]:value});
    }   

    const handleSubmit = async () =>{
        if(validateInput()){
            await login(state).then((response)=>{
                localStorage.setItem('is_authenticated', 1);
                localStorage.setItem('token', response.data.data.token);
                setIsAuthenticated(1);
                
                toast('Customer Login Successfully',{type: 'success'})

                navigate("/");
            }).catch((error) =>{
                console.log("Error", error.response)
                if(error.response.status == 422)
                    toast(error.response.data.error,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
        }
    }

    const validateInput = () => {
        if(state.email == ''){
            toast("Please enter email.",{
                type:'error'
            });
            return false
        }
        if(state.password == ''){
            toast("Please enter password.",{
                type:'error'
            });
            return false
        }
        
        return true;
    }
    const _handleKeyDown = (e) =>{
        if (e.key === 'Enter') {
            handleSubmit();
          }
    }

    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div className="shop-content my-account-page">
                        <div className="container">
                            <nav className="woocommerce-breadcrumb"><ul><li><a href="#">Home</a></li><li>My account</li></ul></nav>
                            <div className="woocommerce"><div className="woocommerce-notices-wrapper"></div>
                                <div className="site-login">
                                    <div className="site-login-container">
                                        <div className="site-login-overflow">
                                            <ul className="login-page-tab">
                                                <li><a href="#" className="active">Login</a></li>

                                                <li> <Link to="/register"> Register </Link> </li>
                                            </ul>
                                            <div id="customer_login" className="login-form-container">
                                                <div className="login-form">
                                                    <form className="woocommerce-form woocommerce-form-login login" method="post">
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="username">Username or email address&nbsp;<span className="required">*</span></label>
                                                            <input onKeyDown={(e)=> _handleKeyDown(e)} onChange={(e) => handleInputChange(e) } type="text" className="woocommerce-Input woocommerce-Input--text input-text" name='email' id="username" autoComplete="username" value={state.email} />
                                                        </p>
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="password">Password&nbsp;<span className="required">*</span></label>
                                                            <span className="password-input"><input onKeyDown={(e)=> _handleKeyDown(e)} onChange={(e) => handleInputChange(e)} className="woocommerce-Input woocommerce-Input--text input-text" type={passwordType?'text':'password'} value={state.password} name="password" id="password" autoComplete="current-password" /><i className={"show-password " + (passwordType?"klbth-icon-circle-empty":"klbth-icon-circle")}  title="Show Password" onClick={()=> setpasswordType(!passwordType)}></i></span>
                                                        </p>


                                                        <p className="form-row">
                                                            <label className="woocommerce-form__label woocommerce-form__label-htmlFor-checkbox woocommerce-form-login__rememberme">
                                                                <input onChange={() => { }} className="woocommerce-form__input woocommerce-form__input-checkbox" name="rememberme" type="checkbox" id="rememberme" value="forever" /> <span>Remember me</span>
                                                            </label>
                                                         <button type="button" onClick={()=> handleSubmit()} className="woocommerce-button button woocommerce-form-login__submit" name="login" value="Log in">Log in</button>
                                                        </p>
                                                        <p className="woocommerce-LostPassword lost_password">
                                                            <Link to="/my-account/lost-password">Lost your password?</Link>
                                                        </p>


                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Login;