import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const getAllStates = async() => {
    return new Promise(function (resolve, reject) {
        axios.get('/customer/states')
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const stateByIds = async(ids) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/states-by-ids?ids=${ids}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}
const getPOSStates = async(id='') => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/posstates?id=${id}`,{headers:headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { getAllStates, stateByIds, getPOSStates };