import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "./Navigation";

const Dashboard = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowMenu(false);
  },[location.pathname]);
    return (
        <main id="main" className="site-primary">
        <div className="site-content">
          <div className="homepage-content">
            <div className="shop-content my-account-page">
              <div className="container">
                <nav className="woocommerce-breadcrumb"><ul><li><a href="#https://klbtheme.com/bacola">Home</a></li><li>My account</li></ul></nav>					
                <div className="woocommerce"><div className="row content-wrapper sidebar-right">
                    <div className="col-12 col-md-12 col-lg-12 content-primary">
                      <div className="my-account-wrapper"> {/* my-account-wrapper be closed in myaccount.php */}
                        <div className="my-account-navigation"> 
                          <div className="account-toggle-menu" onClick={() => setShowMenu(!showMenu)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"><line x1="2.3" y1={12} x2="21.8" y2={12} /><line x1="2.3" y1={6} x2="21.8" y2={6} /><line x1="2.3" y1={18} x2="21.8" y2={18} /></svg>
                            Navigation				
                            </div>{/* account-toggle-menu */}
                          <Navigation showMenu={showMenu} setMenu={(val) => setShowMenu(val)} />
                        </div>
                        <div className="woocommerce-MyAccount-content">
                          <div className="woocommerce-notices-wrapper" />
                          <p>
                            Hello <strong>vebaqyh</strong> (not <strong>vebaqyh</strong>? <a href="#https://klbtheme.com/bacola/my-account/customer-logout/?_wpnonce=08927d8a78">Log out</a>)</p>
                          <p>
                            From your account dashboard you can view your <a href="#https://klbtheme.com/bacola/my-account/orders/">recent orders</a>, manage your <a href="#https://klbtheme.com/bacola/my-account/edit-address/">shipping and billing addresses</a>, and <a href="#https://klbtheme.com/bacola/my-account/edit-account/">edit your password and account details</a>.</p>
                        </div>
                      </div> {/* my-account-wrapper closed in navigation.php */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* homepage-content */}
        </div>{/* site-content */}
      </main>
    );
}

export default Dashboard;