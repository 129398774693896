const actions = {
    SET_SEARCH: "SET_SEARCH",
    SET_CART_QTY: "SET_CART_QTY",
    IS_AUTHENCTICATED: "IS_AUTHENTICATED",
    SHOW_MOBILE_SEARCH: "SHOW_MOBILE_SEARCH",
   
};
//Reducer to Handle Actions
const reducer = (state, action) => {
    switch (action.type) {
      case actions.SET_SEARCH:
        return {
          ...state, search: action.query
        };
        
      case actions.SET_CART_QTY:
        return {
          ...state, cart_qty: action.qty
        };
        
      case actions.IS_AUTHENCTICATED:
        return {
          ...state, is_authenticated: action.value
        };
        
      case actions.SHOW_MOBILE_SEARCH:
        return {
          ...state, show_mobile_search: action.value
        };
      
      default:
        return state;
    }
  };

export default reducer;