import { useEffect } from "react";
import { useState } from "react";
import { retriveCategories } from "../../services/CategoryService";
import { addItemToCart, retriveFrequentlyOrderedItems, retriveHomePageItems, retrivePromotedItems } from "../../services/ItemService";
import Banner from "./Banner";
import Category from "./Category";
import Discount from "./Discount";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { convertToSlug, isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const Home = () =>{
   const [topProducts,setTopProducts] = useState([]);
   const [promotedItems,setPromotedItems] = useState([]);
   const [frequentlyOrderedItems,setFrequentlyOrderedItems] = useState([]);
   const [products,setProducts] = useState([]);
   const [bestProducts,setBestProducts] = useState([]);
   const [wishlistItems,setwishlistItems] = useState([]);
   const [promotedTanslate,setPromotedTanslate] = useState(0);
   const [frequentlyTanslate,setFrequentlyTanslate] = useState(0);
   const { setCartQty, cart_qty, is_authenticated } = useContext(AppContext);



   useEffect(()=>{
      getItems();
      getWishlist();
   },[]);

   useEffect(() => {
      if(is_authenticated == 1){
         getPromotedItems();
         getFrequentlyOrderedItems();
      }else{
         setPromotedItems([]);
         setFrequentlyOrderedItems([]);
      }
   },[is_authenticated]);
   
   const getItems = async() => {
      await retriveHomePageItems().then((response)=>{
         if(response.data.data){
            let data = response.data.data;   
            setTopProducts(data.filter((cat,index) => index <= 5));
            setProducts(data.filter((cat,index) => index >5));
         }
     }).catch((error) =>{
         console.log("Error", error)
     });
   }
   
   const getPromotedItems = async() => {
      await retrivePromotedItems().then((response)=>{
         if(response.data.data){
            setPromotedItems(response.data.data);
         }
     }).catch((error) =>{
         console.log("Error", error)
     });
   }
   
   const getFrequentlyOrderedItems = async() => {
      await retriveFrequentlyOrderedItems().then((response)=>{
         if(response.data.data){
            setFrequentlyOrderedItems(response.data.data);
         }
     }).catch((error) =>{
         console.log("Error", error)
     });
   }

   const getWishlist = async () => {
      let items = await localStorage.getItem('wishlist_items')
      
      if(items != null){
            items = JSON.parse(items);
            let wishlist_item_ids = items.map((_item) => {return _item.id})
            setwishlistItems(wishlist_item_ids);
      }
   }

   const toggleWishlist = async(item) =>{
      let items = await localStorage.getItem('wishlist_items')

      let date = new  Date();
      date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " +  date.getFullYear();

      if(items != null){
         items = JSON.parse(items);
         let ids = items.map(item => item['id']);

         if(ids.includes(item.id)){
            let wishlist_items = items.filter((_item) => _item.id != item.id)
            let wishlist_item_ids = wishlist_items.map((_item) => {return _item.id})

            setwishlistItems(wishlist_item_ids);
            localStorage.setItem('wishlist_items',JSON.stringify(wishlist_items)); 
            toast("Item Removed from Wishlist",{type:'success'});
         }else{
            item['wishlist_added_date'] = date;
            items.push(item)
            setwishlistItems([...wishlistItems,item.id])
            localStorage.setItem('wishlist_items',JSON.stringify(items)); 
            toast("Item added into Wishlist",{type:'success'});
         }
      }else{
         let temp = [];
         item['wishlist_added_date'] = date;
         temp.push(item)
         setwishlistItems([item.id])
         localStorage.setItem('wishlist_items',JSON.stringify(temp));

         toast("Item added into Wishlist",{type:'success'});
      }
   }

   const handleAddToCart = (item) => {
      if(isLogin()){
         let item_id = item.id;
         addItemToCart(item_id)
            .then((response) => {
               setCartQty(parseInt(cart_qty) + 1);
               toast('Item Added To Cart Successfully.',{type: 'success'})
            })
            .catch((error) => {
               console.log("Error", error);
               if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
      }else{
         toast('Please login to add item to cart',{type:'error'});
      }
   }
   
    return (
        <main id="main" className="site-primary">  {/* Changes Made */}
      <div className="site-content">
         <div className="homepage-content">
            <div data-elementor-type="wp-page" data-elementor-id="1047" className="elementor elementor-1047">
              <Banner />
              
               <Category />

               <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-3c74cdf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3c74cdf" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0fe1c81"
                        data-id="0fe1c81" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-595fb48 elementor-widget elementor-widget-bacola-counter-product"
                              data-id="595fb48" data-element_type="widget"
                              data-widget_type="bacola-counter-product.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-counter-product">
                                    <div className="module-body">
                                       <div className="klb-counter-product">
                                          <div className="counter-product">
                                             <div className="products">
                                                <div className="product">
                                                   <div className="deals-header">
                                                      <h4 className="entry-title">Deals of the <strong>week!</strong></h4>
                                                      <div className="deals-counter">
                                                         <div className="countdown" data-date="2022/12/16">
                                                            <div className="count-item days">69</div><span>:</span>
                                                            <div className="count-item hours">22</div><span>:</span>
                                                            <div className="count-item minutes">01</div><span>:</span>
                                                            <div className="count-item second">05</div>
                                                         </div>
                                                         <div className="deals-counter-text">Remains until the end of the
                                                            offer</div>
                                                      </div>
                                                   </div>
                                                   <div className="product-wrapper">
                                                      <div className="thumbnail-wrapper">
                                                         <div className="deal-discount">19%</div><Link
                                                            href=""><img
                                                               src="/images/product-image-50-346x310.jpg"
                                                               alt="Chobani Complete Vanilla Greek Yogurt" /></Link>
                                                      </div>
                                                      <div className="content-wrapper">
                                                         <div className="counter-product-header"><span className="price"><del
                                                                  aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>5.49</bdi></span></del>
                                                               <ins><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>4.49</bdi></span></ins></span>
                                                         </div>
                                                         <h3 className="product-title"><Link
                                                               href=""
                                                               title="Chobani Complete Vanilla Greek Yogurt">Chobani
                                                               Complete Vanilla Greek Yogurt</Link></h3>
                                                         <div className="product-meta">
                                                            <div className="product-unit"> 1 kg</div>
                                                            <div className="product-available in-stock">In Stock</div>
                                                         </div>
                                                         <div className="product-rating">
                                                            <div className="star-rating" role="img"
                                                               aria-label="Rated 5.00 out of 5"><span
                                                                  style={{width:'100%'}}>Rated <strong
                                                                     className="rating">5.00</strong> out of 5</span></div>
                                                            <div className="count-rating">1 <span
                                                                  className="rating-text">Ratings</span></div>
                                                         </div>
                                                         <div className="product-count">
                                                            <div className="product-pcs">Available : <span>25</span></div>
                                                            <div className="product-progress"><span className="progress"
                                                                  style={{width: '89%'}}></span></div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div
                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3e01b55"
                        data-id="3e01b55" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-3415f72 elementor-widget elementor-widget-bacola-product-grid"
                              data-id="3415f72" data-element_type="widget"
                              data-widget_type="bacola-product-grid.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-products">
                                    <div className="module-body">
                                       <div className="products column-3 mobile-column-2">
                                          {
                                             topProducts.map((item,index) => {
                                                return (
                                                   <div key={index}
                                                      className="product type-product post-430 status-publish first instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                                                      <div className="product-wrapper product-type-1">
                                                         <div className="thumbnail-wrapper">
                                                            <div className="product-badges">
                                                               {/* <span className="badge style-1 onsale">23%</span> */}
                                                               <span className="badge style-1 recommend">recommended</span></div><Link
                                                               to={"/product/" + item.id  + "/" + convertToSlug(item.item_name)}><img
                                                                  src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                                                  alt={item?.item_name} /></Link>
                                                            <div className="product-buttons"><a
                                                                  href="#/home-3/430"
                                                                  className="detail-bnt quick-view-button"><svg
                                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                     <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                     </path>
                                                                  </svg></a>
                                                               <div
                                                                  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart"
                                                                  data-tinvwl_product_id="430" onClick={() => toggleWishlist(item)}>
                                                                  <div className="tinv-wishlist-clear"></div><a role="button"
                                                                     tabIndex="0" aria-label="Add to Wishlist"
                                                                     className={"tinvwl_add_to_wishlist_button tinvwl-icon-heart tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')}
                                                                     data-tinv-wl-list="[]" data-tinv-wl-product="430"
                                                                     data-tinv-wl-productvariation="0"
                                                                     data-tinv-wl-productvariations="[0]"
                                                                     data-tinv-wl-producttype="simple"
                                                                     data-tinv-wl-action="add"><span
                                                                        className="tinvwl_add_to_wishlist-text">Add to
                                                                        Wishlist</span></a>
                                                                  <div className="tinv-wishlist-clear"></div>
                                                                  <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="content-wrapper">
                                                            <h3 className="product-title"><Link
                                                                  to={"/product/" + item.id  + "/" + convertToSlug(item.item_name)}
                                                                  title="All Natural Italian-Style Chicken Meatballs"> {item.item_name}</Link></h3>
                                                            <div className="product-meta">
                                                               {item.stock > 0 ?
                                                                  <div className="product-available in-stock"> In Stock</div>
                                                                  : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                               }
                                                            </div>
                                                            <div className="product-rating">
                                                               <div className="star-rating" role="img"
                                                                  aria-label="Rated 4.00 out of 5"><span style={{width:'80%'}}>Rated
                                                                     <strong className="rating">4.00</strong> out of 5</span></div>
                                                               <div className="count-rating">1 <span
                                                                     className="rating-text">Ratings</span></div>
                                                                     
                                                            </div>
                                                            <div className="">
                                                               <div className="" ><span style={{fontSize:"12px"}}>Item Code: {item.item_code}</span></div>
                                                               <div className="" ><span style={{fontSize:"12px"}}>Barcode: {item.custom_barcode}</span></div>
                                                            </div>
                                                            <span className="price"><del aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                                                               <ins><span className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price} </bdi></span></ins></span>
                                                            <div className="product-fade-block">
                                                               <div className="product-button-group cart-with-quantity ">
                                                                  <div className="quantity ajax-quantity">
                                                                     <div className="quantity-button minus"><i
                                                                           className="klbth-icon-minus"></i></div>
                                                                           <input onChange={()=>{}} type="text"
                                                                        className="input-text qty text" name="quantity" step="1"
                                                                        min="" max="28" value="1" title="Menge" size="4"
                                                                        inputMode="numeric" />
                                                                     <div className="quantity-button plus"><i
                                                                           className="klbth-icon-plus"></i></div>
                                                                  </div> 
                                                                  <a
                                                                     href="javascript:void(0)"
                                                                     data-quantity="1"
                                                                     onClick={() => handleAddToCart(item)}
                                                                     className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                     data-product_id="430" data-product_sku="ZU49VOR"
                                                                     aria-label={item.item_name}
                                                                     rel="nofollow">Add to cart</a>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="product-content-fade border-info"
                                                         style={{marginBottom: '-48.9974px'}}></div>
                                                   </div>
                                                );
                                             })
                                          }
                                          
                                          
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>


               <Discount />


              {/*  <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-714a95e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="714a95e" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6487eb7"
                        data-id="6487eb7" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-98106b1 elementor-widget elementor-widget-bacola-product-carousel"
                              data-id="98106b1" data-element_type="widget"
                              data-widget_type="bacola-product-carousel.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-carousel">
                                    <div className="module-header">
                                       <div className="column">
                                          <h4 className="entry-title">Best Sellers</h4>
                                          <div className="entry-description">Do not miss the current offers until the end of
                                             March.</div>
                                       </div>
                                       <div className="column"><a className="button button-info-default xsmall rounded"
                                             href="/shop/?orderby=popularity">View All <i
                                                className="klbth-icon-right-arrow"></i></a></div>
                                    </div>
                                    
                                    <div className="module-body">
                                       <div className="slider-wrapper slider-loaded"><svg className="preloader" width="65px"
                                             height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                             <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                                cx="33" cy="33" r="30"></circle>
                                          </svg>
                                          <div className="products site-slider slick-initialized slick-slider"
                                             data-slideshow="5" data-mobile="1" data-slidespeed="1200"
                                             data-arrows="true" data-autoplay="false" data-autospeed=""
                                             data-dots="false"><button className="slick-prev slick-arrow"
                                                aria-label="Previous" type="button" >Previous</button>
                                             <div className="slick-list draggable">
                                                <div className="slick-track"
                                                   style={{opacity: '1', width: '5480px', transform: 'translate3d(-1096px, 0px, 0px)'}}>
                                                   <div
                                                      className="product type-product post-420 status-publish instock product_cat-grocery-staples has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-cloned"
                                                      style={{width: '274px'}} data-slick-index="-4" id=""
                                                      aria-hidden="true" tabIndex="-1">
                                                      <div className="product-wrapper product-type-4">
                                                         <div className="thumbnail-wrapper">
                                                            <div className="product-badges"><span
                                                                  className="badge style-1 onsale">10%</span><span
                                                                  className="badge style-2 organic">Organic</span></div><a
                                                               href="/product/112/blue-diamond-almonds-lightly-salted/"
                                                               tabIndex="-1"><img
                                                                  src="/images/product-image-59-346x310.jpg"
                                                                  alt="Blue Diamond Almonds Lightly Salted" /></a>
                                                            <div className="product-buttons"><a
                                                                  href="#/home-3/420"
                                                                  className="detail-bnt quick-view-button"
                                                                  tabIndex="-1"><svg xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 512 512">
                                                                     <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                     </path>
                                                                  </svg></a>
                                                               <div
                                                                  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart"
                                                                  data-tinvwl_product_id="420">
                                                                  <div className="tinv-wishlist-clear"></div><a
                                                                     role="button" tabIndex="-1"
                                                                     aria-label="Add to Wishlist"
                                                                     className="tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode"
                                                                     data-tinv-wl-list="[]" data-tinv-wl-product="420"
                                                                     data-tinv-wl-productvariation="0"
                                                                     data-tinv-wl-productvariations="[0]"
                                                                     data-tinv-wl-producttype="simple"
                                                                     data-tinv-wl-action="add"><span
                                                                        className="tinvwl_add_to_wishlist-text">Add to
                                                                        Wishlist</span></a>
                                                                  <div className="tinv-wishlist-clear"></div>
                                                                  <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="content-wrapper">
                                                            <h3 className="product-title"><a
                                                                  href="/product/blue-diamond-almonds-lightly-salted/"
                                                                  title="Blue Diamond Almonds Lightly Salted"
                                                                  tabIndex="-1">Blue Diamond Almonds Lightly Salted</a>
                                                            </h3>
                                                            <div className="product-meta">
                                                               <div className="product-available in-stock">In Stock</div>
                                                            </div>
                                                            <div className="product-rating">
                                                               <div className="star-rating" role="img"
                                                                  aria-label="Rated 5.00 out of 5"><span
                                                                     style={{width:'100%'}}>Rated <strong
                                                                        className="rating">5.00</strong> out of 5</span>
                                                               </div>
                                                               <div className="count-rating">1 <span
                                                                     className="rating-text">Ratings</span></div>
                                                            </div><span className="price"><del aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>11.68</bdi></span></del>
                                                               <ins><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>10.58</bdi></span></ins></span>
                                                            <div className="product-button-group cart-with-quantity ">
                                                               <div className="quantity ajax-quantity">
                                                                  <div className="quantity-button minus"><i
                                                                        className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                     type="text" className="input-text qty text"
                                                                     name="quantity" step="1" min="" max="" value="1"
                                                                     title="Menge" size="4" inputMode="numeric"
                                                                     tabIndex="-1" />
                                                                  <div className="quantity-button plus"><i
                                                                        className="klbth-icon-plus"></i></div>
                                                               </div><a
                                                                  href="#/home-3/?add-to-cart=420"
                                                                  data-quantity="1"
                                                                  className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                  data-product_id="420" data-product_sku="UCB59"
                                                                  aria-label="Add “Blue Diamond Almonds Lightly Salted” to your cart"
                                                                  rel="nofollow" tabIndex="-1">Add to cart</a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      className="product type-product post-383 status-publish last instock product_cat-meats-seafood has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-cloned"
                                                      style={{width: '274px'}} data-slick-index="-3" id=""
                                                      aria-hidden="true" tabIndex="-1">
                                                      <div className="product-wrapper product-type-4">
                                                         <div className="thumbnail-wrapper">
                                                            <div className="product-badges"><span
                                                                  className="badge style-1 onsale">16%</span></div><a
                                                               href="/product/encore-seafoods-stuffed-alaskan-salmon/"
                                                               tabIndex="-1"><img
                                                                  src="/images/product-image-48-346x310.jpg"
                                                                  alt="Encore Seafoods Stuffed Alaskan Salmon" /></a>
                                                            <div className="product-buttons"><a
                                                                  href="#/home-3/383"
                                                                  className="detail-bnt quick-view-button"
                                                                  tabIndex="-1"><svg xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 512 512">
                                                                     <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                     </path>
                                                                  </svg></a>
                                                               <div
                                                                  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart"
                                                                  data-tinvwl_product_id="383">
                                                                  <div className="tinv-wishlist-clear"></div><a
                                                                     role="button" tabIndex="-1"
                                                                     aria-label="Add to Wishlist"
                                                                     className="tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode"
                                                                     data-tinv-wl-list="[]" data-tinv-wl-product="383"
                                                                     data-tinv-wl-productvariation="0"
                                                                     data-tinv-wl-productvariations="[0]"
                                                                     data-tinv-wl-producttype="simple"
                                                                     data-tinv-wl-action="add"><span
                                                                        className="tinvwl_add_to_wishlist-text">Add to
                                                                        Wishlist</span></a>
                                                                  <div className="tinv-wishlist-clear"></div>
                                                                  <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="content-wrapper">
                                                            <h3 className="product-title"><a
                                                                  href="/product/encore-seafoods-stuffed-alaskan-salmon/"
                                                                  title="Encore Seafoods Stuffed Alaskan Salmon"
                                                                  tabIndex="-1">Encore Seafoods Stuffed Alaskan
                                                                  Salmon</a></h3>
                                                            <div className="product-meta">
                                                               <div className="product-available in-stock">In Stock</div>
                                                            </div>
                                                            <div className="product-rating">
                                                               <div className="star-rating" role="img"
                                                                  aria-label="Rated 5.00 out of 5"><span
                                                                     style={{width:'100%'}}>Rated <strong
                                                                        className="rating">5.00</strong> out of 5</span>
                                                               </div>
                                                               <div className="count-rating">1 <span
                                                                     className="rating-text">Ratings</span></div>
                                                            </div><span className="price"><del aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>32.49</bdi></span></del>
                                                               <ins><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>27.49</bdi></span></ins></span>
                                                            <div className="product-button-group cart-with-quantity ">
                                                               <div className="quantity ajax-quantity">
                                                                  <div className="quantity-button minus"><i
                                                                        className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                     type="text" className="input-text qty text"
                                                                     name="quantity" step="1" min="" max="67" value="1"
                                                                     title="Menge" size="4" inputMode="numeric"
                                                                     tabIndex="-1" />
                                                                  <div className="quantity-button plus"><i
                                                                        className="klbth-icon-plus"></i></div>
                                                               </div><a
                                                                  href="#/home-3/?add-to-cart=383"
                                                                  data-quantity="1"
                                                                  className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                  data-product_id="383" data-product_sku="A9UKLA7"
                                                                  aria-label="Add “Encore Seafoods Stuffed Alaskan Salmon” to your cart"
                                                                  rel="nofollow" tabIndex="-1">Add to cart</a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      className="product type-product post-390 status-publish first instock product_cat-breakfast-dairy has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-cloned"
                                                      style={{width: '274px'}} data-slick-index="-2" id=""
                                                      aria-hidden="true" tabIndex="-1">
                                                      <div className="product-wrapper product-type-4">
                                                         <div className="thumbnail-wrapper">
                                                            <div className="product-badges"><span
                                                                  className="badge style-1 onsale">19%</span><span
                                                                  className="badge style-1 recommend">Recommended</span>
                                                            </div><a
                                                               href="/product/chobani-complete-vanilla-greek-yogurt/"
                                                               tabIndex="-1"><img
                                                                  src="/images/product-image-50-346x310.jpg"
                                                                  alt="Chobani Complete Vanilla Greek Yogurt" /></a>
                                                            <div className="product-buttons"><a
                                                                  href="#/home-3/390"
                                                                  className="detail-bnt quick-view-button"
                                                                  tabIndex="-1"><svg xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 512 512">
                                                                     <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                     </path>
                                                                  </svg></a>
                                                               <div
                                                                  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart"
                                                                  data-tinvwl_product_id="390">
                                                                  <div className="tinv-wishlist-clear"></div><a
                                                                     role="button" tabIndex="-1"
                                                                     aria-label="Add to Wishlist"
                                                                     className="tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode"
                                                                     data-tinv-wl-list="[]" data-tinv-wl-product="390"
                                                                     data-tinv-wl-productvariation="0"
                                                                     data-tinv-wl-productvariations="[0]"
                                                                     data-tinv-wl-producttype="simple"
                                                                     data-tinv-wl-action="add"><span
                                                                        className="tinvwl_add_to_wishlist-text">Add to
                                                                        Wishlist</span></a>
                                                                  <div className="tinv-wishlist-clear"></div>
                                                                  <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="content-wrapper">
                                                            <h3 className="product-title"><a
                                                                  href="/product/chobani-complete-vanilla-greek-yogurt/"
                                                                  title="Chobani Complete Vanilla Greek Yogurt"
                                                                  tabIndex="-1">Chobani Complete Vanilla Greek
                                                                  Yogurt</a></h3>
                                                            <div className="product-meta">
                                                               <div className="product-unit"> 1 kg</div>
                                                               <div className="product-available in-stock">In Stock</div>
                                                            </div>
                                                            <div className="product-rating">
                                                               <div className="star-rating" role="img"
                                                                  aria-label="Rated 5.00 out of 5"><span
                                                                     style={{width:'100%'}}>Rated <strong
                                                                        className="rating">5.00</strong> out of 5</span>
                                                               </div>
                                                               <div className="count-rating">1 <span
                                                                     className="rating-text">Ratings</span></div>
                                                            </div><span className="price"><del aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>5.49</bdi></span></del>
                                                               <ins><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>4.49</bdi></span></ins></span>
                                                            <div className="product-button-group cart-with-quantity ">
                                                               <div className="quantity ajax-quantity">
                                                                  <div className="quantity-button minus"><i
                                                                        className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                     type="text" className="input-text qty text"
                                                                     name="quantity" step="1" min="" max="25" value="1"
                                                                     title="Menge" size="4" inputMode="numeric"
                                                                     tabIndex="-1" />
                                                                  <div className="quantity-button plus"><i
                                                                        className="klbth-icon-plus"></i></div>
                                                               </div><a
                                                                  href="#/home-3/?add-to-cart=390"
                                                                  data-quantity="1"
                                                                  className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                  data-product_id="390" data-product_sku="ML76AP"
                                                                  aria-label="Add “Chobani Complete Vanilla Greek Yogurt” to your cart"
                                                                  rel="nofollow" tabIndex="-1">Add to cart</a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      className="product type-product post-231 status-publish instock product_cat-meats-seafood product_tag-meat product_tag-stew has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-cloned"
                                                      style={{width: '274px'}} data-slick-index="-1" id=""
                                                      aria-hidden="true" tabIndex="-1">
                                                      <div className="product-wrapper product-type-4">
                                                         <div className="thumbnail-wrapper">
                                                            <div className="product-badges"><span
                                                                  className="badge style-1 onsale">38%</span></div><a
                                                               href="/product/112/usda-choice-angus-beef-stew-meat/"
                                                               tabIndex="-1"><img
                                                                  src="/images/product-image-7-346x310.jpg"
                                                                  alt="USDA Choice Angus Beef Stew Meat" /></a>
                                                            <div className="product-buttons"><a
                                                                  href="#/home-3/231"
                                                                  className="detail-bnt quick-view-button"
                                                                  tabIndex="-1"><svg xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 512 512">
                                                                     <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                     </path>
                                                                  </svg></a>
                                                               <div
                                                                  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart"
                                                                  data-tinvwl_product_id="231">
                                                                  <div className="tinv-wishlist-clear"></div><a
                                                                     role="button" tabIndex="-1"
                                                                     aria-label="Add to Wishlist"
                                                                     className="tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode"
                                                                     data-tinv-wl-list="[]" data-tinv-wl-product="231"
                                                                     data-tinv-wl-productvariation="0"
                                                                     data-tinv-wl-productvariations="[0]"
                                                                     data-tinv-wl-producttype="simple"
                                                                     data-tinv-wl-action="add"><span
                                                                        className="tinvwl_add_to_wishlist-text">Add to
                                                                        Wishlist</span></a>
                                                                  <div className="tinv-wishlist-clear"></div>
                                                                  <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="content-wrapper">
                                                            <h3 className="product-title"><a
                                                                  href="/product/112/usda-choice-angus-beef-stew-meat/"
                                                                  title="USDA Choice Angus Beef Stew Meat"
                                                                  tabIndex="-1">USDA Choice Angus Beef Stew Meat</a>
                                                            </h3>
                                                            <div className="product-meta">
                                                               <div className="product-available in-stock">In Stock</div>
                                                            </div>
                                                            <div className="product-rating">
                                                               <div className="star-rating" role="img"
                                                                  aria-label="Rated 4.00 out of 5"><span
                                                                     style={{width:'80%'}}>Rated <strong
                                                                        className="rating">4.00</strong> out of 5</span>
                                                               </div>
                                                               <div className="count-rating">1 <span
                                                                     className="rating-text">Ratings</span></div>
                                                            </div><span className="price"><del aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>79.99</bdi></span></del>
                                                               <ins><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>49.99</bdi></span></ins></span>
                                                            <div className="product-button-group cart-with-quantity ">
                                                               <div className="quantity ajax-quantity">
                                                                  <div className="quantity-button minus"><i
                                                                        className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                     type="text" className="input-text qty text"
                                                                     name="quantity" step="1" min="" max="" value="1"
                                                                     title="Menge" size="4" inputMode="numeric"
                                                                     tabIndex="-1" />
                                                                  <div className="quantity-button plus"><i
                                                                        className="klbth-icon-plus"></i></div>
                                                               </div><a
                                                                  href="#/home-3/?add-to-cart=231"
                                                                  data-quantity="1"
                                                                  className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                  data-product_id="231" data-product_sku="YE45VGRT"
                                                                  aria-label="Add “USDA Choice Angus Beef Stew Meat” to your cart"
                                                                  rel="nofollow" tabIndex="-1">Add to cart</a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   {
                                                      bestProducts.map((item,index)=>{
                                                         return (
                                                            <div key={index}
                                                               className="product type-product post-430 status-publish instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-current slick-active"
                                                               style={{width: '274px'}} data-slick-index="0" aria-hidden="false"
                                                               tabIndex="0">
                                                               <div className="product-wrapper product-type-4">
                                                                  <div className="thumbnail-wrapper">
                                                                     <div className="product-badges"><span
                                                                           className="badge style-1 onsale">23%</span><span
                                                                           className="badge style-1 recommend">recommended</span>
                                                                     </div><Link
                                                                        to={"/product/" + item.id  + "/" + item.item_name}
                                                                        tabIndex="0"><img
                                                                           src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/product-image-62-346x310.jpg"}
                                                                           alt="All Natural Italian-Style Chicken Meatballs" /></Link>
                                                                     <div className="product-buttons"><a
                                                                           href="#/home-3/430"
                                                                           className="detail-bnt quick-view-button" tabIndex="0"><svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 512 512">
                                                                              <path
                                                                                 d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                              </path>
                                                                           </svg></a>
                                                                        <div
                                                                           className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" onClick={() => toggleWishlist(item)}
                                                                           data-tinvwl_product_id="430">
                                                                           <div className="tinv-wishlist-clear"></div><a
                                                                              role="button" tabIndex="0"
                                                                              aria-label="Add to Wishlist"
                                                                              className={ "tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')}
                                                                              data-tinv-wl-list="[]" data-tinv-wl-product="430"
                                                                              data-tinv-wl-productvariation="0"
                                                                              data-tinv-wl-productvariations="[0]"
                                                                              data-tinv-wl-producttype="simple"
                                                                              data-tinv-wl-action="add"><span
                                                                                 className="tinvwl_add_to_wishlist-text">Add to
                                                                                 Wishlist</span></a>
                                                                           <div className="tinv-wishlist-clear"></div>
                                                                           <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div className="content-wrapper">
                                                                     <h3 className="product-title"><Link
                                                                           to={"/product/" + item.id  + "/" + item.item_name}
                                                                           title={item.item_name}
                                                                           tabIndex="0">{item.item_name}</Link></h3>
                                                                     <div className="product-meta">
                                                                     {item.stock > 0 ?
                                                                        <div className="product-available in-stock"> In Stock</div>
                                                                        : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                                     }
                                                                     </div>
                                                                     <div className="product-rating">
                                                                        <div className="star-rating" role="img"
                                                                           aria-label="Rated 4.00 out of 5"><span
                                                                              style={{width:'80%'}}>Rated <strong
                                                                                 className="rating">4.00</strong> out of 5</span>
                                                                        </div>
                                                                        <div className="count-rating">1 <span
                                                                              className="rating-text">Ratings</span></div>
                                                                     </div><span className="price"><del aria-hidden="true"><span
                                                                              className="woocommerce-Price-amount amount"><bdi><span
                                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                                                                        <ins><span
                                                                              className="woocommerce-Price-amount amount"><bdi><span
                                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price}</bdi></span></ins></span>
                                                                     <div className="product-button-group cart-with-quantity ">
                                                                        <div className="quantity ajax-quantity">
                                                                           <div className="quantity-button minus"><i
                                                                                 className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                              type="text" className="input-text qty text"
                                                                              name="quantity" step="1" min="" max="28" value="1"
                                                                              title="Menge" size="4" inputMode="numeric"
                                                                              tabIndex="0" />
                                                                           <div className="quantity-button plus"><i
                                                                                 className="klbth-icon-plus"></i></div>
                                                                        </div>
                                                                        <a
                                                                           href=""
                                                                           onClick={(e) => { e.preventDefault(); handleAddToCart(item) }}
                                                                           data-quantity="1"
                                                                           className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                           data-product_id="430" data-product_sku="ZU49VOR"
                                                                           rel="nofollow" tabIndex="0">Add to cart</a>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         );
                                                      })
                                                   }
                                                  
                                                   
                                                </div>
                                             </div><button className="slick-next slick-arrow" aria-label="Next"
                                                type="button" >Next</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section> */}
               
               {/* Promoted Products */}
               {
                  promotedItems.length > 0 ?
               
               <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-714a95e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="714a95e" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6487eb7"
                        data-id="6487eb7" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-98106b1 elementor-widget elementor-widget-bacola-product-carousel"
                              data-id="98106b1" data-element_type="widget"
                              data-widget_type="bacola-product-carousel.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-carousel">
                                    <div className="module-header">
                                       <div className="column">
                                          <h4 className="entry-title">Promoted Products</h4>
                                          <div className="entry-description">Do not miss the current offers until the end of
                                             March.</div>
                                       </div>
                                       {/* <div className="column"><a className="button button-info-default xsmall rounded"
                                             href="/shop/?orderby=popularity">View All <i
                                                className="klbth-icon-right-arrow"></i></a></div> */}
                                    </div>
                                    
                                    <div className="module-body">
                                       <div className="slider-wrapper slider-loaded"><svg className="preloader" width="65px"
                                             height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                             <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                                cx="33" cy="33" r="30"></circle>
                                          </svg>
                                          <div className="products site-slider slick-initialized slick-slider"
                                             data-slideshow="5" data-mobile="1" data-slidespeed="1200"
                                             data-arrows="true" data-autoplay="false" data-autospeed=""
                                             data-dots="false"><button className="slick-prev slick-arrow"
                                                aria-label="Previous" type="button"  onClick={() => { if(promotedTanslate >= 0){ setPromotedTanslate(0)}else{setPromotedTanslate(promotedTanslate + 235)}}}>Previous</button>
                                             <div className="slick-list draggable">
                                                <div className="slick-track add-trans" style={{opacity: '1', width: '5480px', transform: `translate3d(${promotedTanslate}px, 0px, 0px)`}}>  {/*  */}
                                                   
                                                   {
                                                      promotedItems.map((item,index)=>{
                                                         return (
                                                            <div key={index}
                                                               className="product type-product post-430 status-publish instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-current slick-active"
                                                               style={{width: '274px'}} data-slick-index="0" aria-hidden="false"
                                                               tabIndex="0">
                                                               <div className="product-wrapper product-type-4">
                                                                  <div className="thumbnail-wrapper">
                                                                     <div className="product-badges">
                                                                        {/* <span className="badge style-1 onsale">23%</span> */}
                                                                        <span className="badge style-1 recommend">recommended</span>
                                                                     </div><Link
                                                                        to={"/product/" + item.id  + "/" + convertToSlug(item.item_name)}
                                                                        tabIndex="0"><img
                                                                           src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                                                           alt="All Natural Italian-Style Chicken Meatballs" /></Link>
                                                                     <div className="product-buttons"><a
                                                                           href="#/home-3/430"
                                                                           className="detail-bnt quick-view-button" tabIndex="0"><svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 512 512">
                                                                              <path
                                                                                 d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                              </path>
                                                                           </svg></a>
                                                                        <div
                                                                           className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" onClick={() => toggleWishlist(item)}
                                                                           data-tinvwl_product_id="430">
                                                                           <div className="tinv-wishlist-clear"></div><a
                                                                              role="button" tabIndex="0"
                                                                              aria-label="Add to Wishlist"
                                                                              className={ "tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')}
                                                                              data-tinv-wl-list="[]" data-tinv-wl-product="430"
                                                                              data-tinv-wl-productvariation="0"
                                                                              data-tinv-wl-productvariations="[0]"
                                                                              data-tinv-wl-producttype="simple"
                                                                              data-tinv-wl-action="add"><span
                                                                                 className="tinvwl_add_to_wishlist-text">Add to
                                                                                 Wishlist</span></a>
                                                                           <div className="tinv-wishlist-clear"></div>
                                                                           <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div className="content-wrapper">
                                                                     <h3 className="product-title"><Link
                                                                           to={"/product/" + item.id  + "/" + convertToSlug(item.item_name)}
                                                                           title={item.item_name}
                                                                           tabIndex="0">{item.item_name}</Link></h3>
                                                                     <div className="product-meta">
                                                                     {item.stock > 0 ?
                                                                        <div className="product-available in-stock"> In Stock</div>
                                                                        : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                                     }
                                                                     </div>
                                                                     <div className="product-rating">
                                                                        <div className="star-rating" role="img"
                                                                           aria-label="Rated 4.00 out of 5"><span
                                                                              style={{width:'80%'}}>Rated <strong
                                                                                 className="rating">4.00</strong> out of 5</span>
                                                                        </div>
                                                                        <div className="count-rating">1 <span
                                                                              className="rating-text">Ratings</span></div>
                                                                     </div><span className="price"><del aria-hidden="true"><span
                                                                              className="woocommerce-Price-amount amount"><bdi><span
                                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                                                                        <ins><span
                                                                              className="woocommerce-Price-amount amount"><bdi><span
                                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price}</bdi></span></ins></span>
                                                                     <div className="product-button-group cart-with-quantity ">
                                                                        <div className="quantity ajax-quantity">
                                                                           <div className="quantity-button minus"><i
                                                                                 className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                              type="text" className="input-text qty text"
                                                                              name="quantity" step="1" min="" max="28" value="1"
                                                                              title="Menge" size="4" inputMode="numeric"
                                                                              tabIndex="0" />
                                                                           <div className="quantity-button plus"><i
                                                                                 className="klbth-icon-plus"></i></div>
                                                                        </div>
                                                                        <a
                                                                           href=""
                                                                           onClick={(e) => { e.preventDefault(); handleAddToCart(item) }}
                                                                           data-quantity="1"
                                                                           className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                           data-product_id="430" data-product_sku="ZU49VOR"
                                                                           rel="nofollow" tabIndex="0">Add to cart</a>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         );
                                                      })
                                                   }
                                                  
                                                   
                                                </div>
                                             </div><button className="slick-next slick-arrow" aria-label="Next"
                                                type="button" onClick={() => { if(promotedTanslate == -2585 || (0-(promotedItems?.length-1) * 235) > promotedTanslate){ setPromotedTanslate(0)}else{setPromotedTanslate(promotedTanslate - 235)}}} >Next</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               :null
               }
               
               {/* Frequently Ordered Products */}
               {
                  frequentlyOrderedItems.length > 0 ?
               
               <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-714a95e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="714a95e" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6487eb7"
                        data-id="6487eb7" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-98106b1 elementor-widget elementor-widget-bacola-product-carousel"
                              data-id="98106b1" data-element_type="widget"
                              data-widget_type="bacola-product-carousel.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-carousel">
                                    <div className="module-header">
                                       <div className="column">
                                          <h4 className="entry-title">Frequently Ordered Products</h4>
                                          <div className="entry-description">Do not miss the current offers until the end of March.</div>
                                       </div>
                                       {/* <div className="column"><a className="button button-info-default xsmall rounded"
                                             href="/shop/?orderby=popularity">View All <i
                                                className="klbth-icon-right-arrow"></i></a></div> */}
                                    </div>
                                    
                                    <div className="module-body">
                                       <div className="slider-wrapper slider-loaded"><svg className="preloader" width="65px"
                                             height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                             <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                                cx="33" cy="33" r="30"></circle>
                                          </svg>
                                          <div className="products site-slider slick-initialized slick-slider"
                                             data-slideshow="5" data-mobile="1" data-slidespeed="1200"
                                             data-arrows="true" data-autoplay="false" data-autospeed=""
                                             data-dots="false"><button className="slick-prev slick-arrow"
                                                aria-label="Previous" type="button"  onClick={() => { if(frequentlyTanslate >= 0){ setFrequentlyTanslate(0)}else{setFrequentlyTanslate(frequentlyTanslate + 235)}}}>Previous</button>
                                             <div className="slick-list draggable">
                                                <div className="slick-track add-trans" style={{opacity: '1', width: '5480px', transform: `translate3d(${frequentlyTanslate}px, 0px, 0px)`}}>  {/*  */}
                                                   
                                                   {
                                                      frequentlyOrderedItems.map((item,index)=>{
                                                         return (
                                                            <div key={index}
                                                               className="product type-product post-430 status-publish instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple slick-slide slick-current slick-active"
                                                               style={{width: '274px'}} data-slick-index="0" aria-hidden="false"
                                                               tabIndex="0">
                                                               <div className="product-wrapper product-type-4">
                                                                  <div className="thumbnail-wrapper">
                                                                     <div className="product-badges">
                                                                        {/* <span className="badge style-1 onsale">23%</span> */}
                                                                        <span className="badge style-1 recommend">recommended</span>
                                                                     </div><Link
                                                                        to={"/product/" + item.id  + "/" + convertToSlug(item.item_name)}
                                                                        tabIndex="0"><img
                                                                           src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                                                           alt="All Natural Italian-Style Chicken Meatballs" /></Link>
                                                                     <div className="product-buttons"><a
                                                                           href="#/home-3/430"
                                                                           className="detail-bnt quick-view-button" tabIndex="0"><svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 512 512">
                                                                              <path
                                                                                 d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                              </path>
                                                                           </svg></a>
                                                                        <div
                                                                           className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" onClick={() => toggleWishlist(item)}
                                                                           data-tinvwl_product_id="430">
                                                                           <div className="tinv-wishlist-clear"></div><a
                                                                              role="button" tabIndex="0"
                                                                              aria-label="Add to Wishlist"
                                                                              className={ "tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')}
                                                                              data-tinv-wl-list="[]" data-tinv-wl-product="430"
                                                                              data-tinv-wl-productvariation="0"
                                                                              data-tinv-wl-productvariations="[0]"
                                                                              data-tinv-wl-producttype="simple"
                                                                              data-tinv-wl-action="add"><span
                                                                                 className="tinvwl_add_to_wishlist-text">Add to
                                                                                 Wishlist</span></a>
                                                                           <div className="tinv-wishlist-clear"></div>
                                                                           <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div className="content-wrapper">
                                                                     <h3 className="product-title"><Link
                                                                           to={"/product/" + item.id  + "/" + convertToSlug(item.item_name)}
                                                                           title={item.item_name}
                                                                           tabIndex="0">{item.item_name}</Link></h3>
                                                                     <div className="product-meta">
                                                                     {item.stock > 0 ?
                                                                        <div className="product-available in-stock"> In Stock</div>
                                                                        : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                                     }
                                                                     </div>
                                                                     <div className="product-rating">
                                                                        <div className="star-rating" role="img"
                                                                           aria-label="Rated 4.00 out of 5"><span
                                                                              style={{width:'80%'}}>Rated <strong
                                                                                 className="rating">4.00</strong> out of 5</span>
                                                                        </div>
                                                                        <div className="count-rating">1 <span
                                                                              className="rating-text">Ratings</span></div>
                                                                     </div><span className="price"><del aria-hidden="true"><span
                                                                              className="woocommerce-Price-amount amount"><bdi><span
                                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                                                                        <ins><span
                                                                              className="woocommerce-Price-amount amount"><bdi><span
                                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price}</bdi></span></ins></span>
                                                                     <div className="product-button-group cart-with-quantity ">
                                                                        <div className="quantity ajax-quantity">
                                                                           <div className="quantity-button minus"><i
                                                                                 className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                              type="text" className="input-text qty text"
                                                                              name="quantity" step="1" min="" max="28" value="1"
                                                                              title="Menge" size="4" inputMode="numeric"
                                                                              tabIndex="0" />
                                                                           <div className="quantity-button plus"><i
                                                                                 className="klbth-icon-plus"></i></div>
                                                                        </div>
                                                                        <a
                                                                           href=""
                                                                           onClick={(e) => { e.preventDefault(); handleAddToCart(item) }}
                                                                           data-quantity="1"
                                                                           className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                           data-product_id="430" data-product_sku="ZU49VOR"
                                                                           rel="nofollow" tabIndex="0">Add to cart</a>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         );
                                                      })
                                                   }
                                                  
                                                   
                                                </div>
                                             </div><button className="slick-next slick-arrow" aria-label="Next"
                                                type="button" onClick={() => { if(frequentlyTanslate == -2585 || (0-(frequentlyOrderedItems?.length-1) * 235) > frequentlyTanslate){ setFrequentlyTanslate(0)}else{setFrequentlyTanslate(frequentlyTanslate - 235)}}} >Next</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               : null
               }

               <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-f938cad elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="f938cad" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d6f9caa"
                        data-id="d6f9caa" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-fbfda28 elementor-widget elementor-widget-bacola-text-banner"
                              data-id="fbfda28" data-element_type="widget"
                              data-widget_type="bacola-text-banner.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-banner simple-text">
                                    <div className="module-body">
                                       <a href="" onClick={(e) => e.preventDefault()}>Save an Extra 5-10 % On Every Autoship Order!</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-9b6cb9e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="9b6cb9e" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6a1e7d4"
                        data-id="6a1e7d4" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-828afce elementor-widget elementor-widget-bacola-product-grid"
                              data-id="828afce" data-element_type="widget"
                              data-widget_type="bacola-product-grid.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-products">
                                    <div className="module-body">
                                       <div className="products column-5 mobile-column-2">
                                          {
                                             products.map((item, index)=>{
                                                return (
                                                   <div key={index}
                                                      className="product type-product post-196 status-publish instock product_cat-beverages product_tag-fresh product_tag-juices has-post-thumbnail sale shipping-taxable product-type-external">
                                                      <div className="product-wrapper product-type-1">
                                                         <div className="thumbnail-wrapper">
                                                            <div className="product-badges">
                                                               {/* <span className="badge style-1 onsale">26%</span> */}
                                                               </div>
                                                                  <Link to={"/product/" + item.id + "/" + convertToSlug(item.item_name)}>
                                                                     <img src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                                                  alt={item.item_name} /></Link>
                                                            <div className="product-buttons"><a
                                                                  href="#/home-3/196"
                                                                  className="detail-bnt quick-view-button"><svg
                                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                     <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                     </path>
                                                                  </svg></a>
                                                               <div
                                                                  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" onClick={() => toggleWishlist(item)}
                                                                  data-tinvwl_product_id="196">
                                                                  <div className="tinv-wishlist-clear"></div><a role="button"
                                                                     tabIndex="0" aria-label="Add to Wishlist"
                                                                     className={ "tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')}
                                                                     data-tinv-wl-list="[]" data-tinv-wl-product="196"
                                                                     data-tinv-wl-productvariation="0"
                                                                     data-tinv-wl-productvariations="[0]"
                                                                     data-tinv-wl-producttype="external"
                                                                     data-tinv-wl-action="add"><span
                                                                        className="tinvwl_add_to_wishlist-text">Add to
                                                                        Wishlist</span></a>
                                                                  <div className="tinv-wishlist-clear"></div>
                                                                  <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="content-wrapper">
                                                            <h3 className="product-title"><Link
                                                                  to={"/product/" + item.id + "/" + convertToSlug(item.item_name)}
                                                                  title={item.item_name}>{item.item_name}</Link></h3>
                                                            <div className="product-meta">
                                                            {item.stock > 0 ?
                                                               <div className="product-available in-stock"> In Stock</div>
                                                               : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                            }
                                                            </div>
                                                            <div className="product-rating">
                                                               <div className="star-rating" role="img"
                                                                  aria-label="Rated 5.00 out of 5"><span style={{width:'100%'}}>Rated
                                                                     <strong className="rating">5.00</strong> out of 5</span></div>
                                                               <div className="count-rating">1 <span
                                                                     className="rating-text">Ratings</span></div>
                                                            </div>
                                                            <div className="">
                                                               <div className="" ><span style={{fontSize:"12px"}}>Item Code: {item.item_code}</span></div>
                                                               <div className="" ><span style={{fontSize:"12px"}}>Barcode: {item.custom_barcode}</span></div>
                                                            </div>
                                                            <span className="price"><del aria-hidden="true"><span
                                                                     className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                                                               <ins><span className="woocommerce-Price-amount amount"><bdi><span
                                                                           className="woocommerce-Price-currencySymbol">$</span> {item.purchase_price} </bdi></span></ins></span>
                                                            <div className="product-fade-block">
                                                               <div className="product-button-group cart-with-quantity ">
                                                                  <div className="quantity ajax-quantity">
                                                                     <div className="quantity-button minus"><i
                                                                           className="klbth-icon-minus"></i></div><input onChange={()=>{}} type="text"
                                                                        className="input-text qty text" name="quantity" step="1"
                                                                        min="" max="" value="1" title="Menge" size="4"
                                                                        inputMode="numeric" />
                                                                     <div className="quantity-button plus"><i
                                                                           className="klbth-icon-plus"></i></div>
                                                                  </div><a
                                                                     href=""
                                                                     onClick={(e) => { e.preventDefault(); handleAddToCart(item) }}
                                                                     data-quantity="1"
                                                                     className="button-primary xsmall rounded wide button product_type_external"
                                                                     data-product_id="196" data-product_sku="BE45VGRT"
                                                                     rel="nofollow">Buy product</a>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="product-content-fade border-info"
                                                         style={{marginBottom: '-48.9974px'}}></div>
                                                   </div>
                                                );
                                             })
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               {/* <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-71dd98b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="71dd98b" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-df7d26a"
                        data-id="df7d26a" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-95a07c7 elementor-widget elementor-widget-bacola-banner-box3"
                              data-id="95a07c7" data-element_type="widget"
                              data-widget_type="bacola-banner-box3.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-banner image align-left align-center">
                                    <div className="module-body">
                                       <div className="banner-wrapper">
                                          <div className="banner-content">
                                             <div className="content-header">
                                                <div className="discount-text color-success">Weekend Discount 20%</div>
                                             </div>
                                             <div className="content-main">
                                                <h3 className="entry-title color-text-light">Natural Eggs</h3>
                                                <div className="entry-text color-info-dark">Eat one every day</div>
                                             </div><a
                                                href="/product/112/organic-cage-free-grade-a-large-brown-eggs/"
                                                className="button button-info-dark rounded xsmall">Shop Now</a>
                                          </div>
                                          <div className="banner-thumbnail"><img src="/images/bacola-banner-05.jpg"
                                                alt="banner" /></div><a
                                             href="/product/112/organic-cage-free-grade-a-large-brown-eggs/"
                                             className="overlay-link"></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div
                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-695f584"
                        data-id="695f584" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-a42156b elementor-widget elementor-widget-bacola-banner-box3"
                              data-id="a42156b" data-element_type="widget"
                              data-widget_type="bacola-banner-box3.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-banner image align-left align-center">
                                    <div className="module-body">
                                       <div className="banner-wrapper">
                                          <div className="banner-content">
                                             <div className="content-header">
                                                <div className="discount-text color-success">Weekend Discount 40%</div>
                                             </div>
                                             <div className="content-main">
                                                <h3 className="entry-title color-text-light">Taste the Best</h3>
                                                <div className="entry-text color-info-dark">Shine the morning</div>
                                             </div><a href="/shop/?filter_cat=57,32"
                                                className="button button-info-dark rounded xsmall">Shop Now</a>
                                          </div>
                                          <div className="banner-thumbnail"><img src="/images/bacola-banner-06.jpg"
                                                alt="banner" /></div><a
                                             href="/shop/?filter_cat=57,32"
                                             className="overlay-link"></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div
                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-18b3b1a"
                        data-id="18b3b1a" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-31534f1 elementor-widget elementor-widget-bacola-banner-box3"
                              data-id="31534f1" data-element_type="widget"
                              data-widget_type="bacola-banner-box3.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-banner image align-left align-center">
                                    <div className="module-body">
                                       <div className="banner-wrapper">
                                          <div className="banner-content">
                                             <div className="content-header">
                                                <div className="discount-text color-success">Weekend Discount 30%</div>
                                             </div>
                                             <div className="content-main">
                                                <h3 className="entry-title color-text-light">Ditch the Junk
                                                </h3>
                                                <div className="entry-text color-info-dark">Breakfast made better</div>
                                             </div><a href="/shop/?filter_cat=36,60"
                                                className="button button-info-dark rounded xsmall">Shop Now</a>
                                          </div>
                                          <div className="banner-thumbnail"><img src="/images/bacola-banner-10.jpg"
                                                alt="banner" /></div><a
                                             href="/shop/?filter_cat=36,60"
                                             className="overlay-link"></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section> */}
               {/* <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-a966706 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="a966706" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-extended">
                     <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9626a72"
                        data-id="9626a72" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div
                              className="elementor-element elementor-element-8903056 elementor-widget elementor-widget-bacola-latest-blog"
                              data-id="8903056" data-element_type="widget"
                              data-widget_type="bacola-latest-blog.default">
                              <div className="elementor-widget-container">
                                 <div className="site-module module-blog">
                                    <div className="module-body">
                                       <div className="row">
                                          <div className="col-12 col-lg-4">
                                             <article className="post">
                                                <figure className="post-thumbnail"><a
                                                      href="/blog/but-i-must-explain-to-you-how-all-this-mistaken-idea/"><img
                                                         src="/images/blog-3-370x260.jpg"
                                                         alt="But I must explain to you how all this mistaken idea" /></a>
                                                </figure>
                                                <div className="post-wrapper">
                                                   <div className="entry-category"><a
                                                         href="/blog/but-i-must-explain-to-you-how-all-this-mistaken-idea/">Tips
                                                         &amp; Tricks</a></div>
                                                   <h2 className="entry-title"><a
                                                         href="/blog/but-i-must-explain-to-you-how-all-this-mistaken-idea/">But
                                                         I must explain to you how all this mistaken idea</a></h2>
                                                   <div className="entry-meta"><span className="meta-item entry-published"
                                                         itemProp="datePublished"><a
                                                            href="/blog/but-i-must-explain-to-you-how-all-this-mistaken-idea/"
                                                            itemProp="url">3 May 2021</a></span><span
                                                         className="meta-item entry-comments"><a
                                                            href="#/home-3/#">3 comments</a>
                                                      </span><span className="meta-item entry-author" itemProp="author"
                                                         itemScope="itemscope" itemType="http://schema.org/Person"><span
                                                            className="meta-separator">by </span><a
                                                            href="/blog/but-i-must-explain-to-you-how-all-this-mistaken-idea/"
                                                            rel="author" className="url fn n" itemProp="url"><span
                                                               itemProp="name">Bacola</span></a></span></div>
                                                </div>
                                             </article>
                                          </div>
                                          <div className="col-12 col-lg-4">
                                             <article className="post">
                                                <figure className="post-thumbnail"><a
                                                      href="/blog/the-problem-with-typefaces-on-the-web/"><img
                                                         src="/images/blog-5-370x260.jpg"
                                                         alt="The Problem With Typefaces on the Web" /></a></figure>
                                                <div className="post-wrapper">
                                                   <div className="entry-category"><a
                                                         href="/blog/the-problem-with-typefaces-on-the-web/">Grocery</a>
                                                   </div>
                                                   <h2 className="entry-title"><a
                                                         href="/blog/the-problem-with-typefaces-on-the-web/">The
                                                         Problem With Typefaces on the Web</a></h2>
                                                   <div className="entry-meta"><span className="meta-item entry-published"
                                                         itemProp="datePublished"><a
                                                            href="/blog/the-problem-with-typefaces-on-the-web/"
                                                            itemProp="url">3 May 2021</a></span><span
                                                         className="meta-item entry-comments"><a
                                                            href="#/home-3/#">3 comments</a>
                                                      </span><span className="meta-item entry-author" itemProp="author"
                                                         itemScope="itemscope" itemType="http://schema.org/Person"><span
                                                            className="meta-separator">by </span><a
                                                            href="/blog/the-problem-with-typefaces-on-the-web/"
                                                            rel="author" className="url fn n" itemProp="url"><span
                                                               itemProp="name">Bacola</span></a></span></div>
                                                </div>
                                             </article>
                                          </div>
                                          <div className="col-12 col-lg-4">
                                             <article className="post">
                                                <figure className="post-thumbnail"><a
                                                      href="/blog/english-breakfast-tea-with-tasty-donut-desserts/"><img
                                                         src="/images/blog-1-370x260.jpg"
                                                         alt="English Breakfast Tea With Tasty Donut Desserts" /></a>
                                                </figure>
                                                <div className="post-wrapper">
                                                   <div className="entry-category"><a
                                                         href="/blog/english-breakfast-tea-with-tasty-donut-desserts/">Grocery</a>
                                                   </div>
                                                   <h2 className="entry-title"><a
                                                         href="/blog/english-breakfast-tea-with-tasty-donut-desserts/">English
                                                         Breakfast Tea With Tasty Donut Desserts</a></h2>
                                                   <div className="entry-meta"><span className="meta-item entry-published"
                                                         itemProp="datePublished"><a
                                                            href="/blog/english-breakfast-tea-with-tasty-donut-desserts/"
                                                            itemProp="url">2 May 2021</a></span><span
                                                         className="meta-item entry-comments"><a
                                                            href="#/home-3/#">3 comments</a>
                                                      </span><span className="meta-item entry-author" itemProp="author"
                                                         itemScope="itemscope" itemType="http://schema.org/Person"><span
                                                            className="meta-separator">by </span><a
                                                            href="/blog/english-breakfast-tea-with-tasty-donut-desserts/"
                                                            rel="author" className="url fn n" itemProp="url"><span
                                                               itemProp="name">Bacola</span></a></span></div>
                                                </div>
                                             </article>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section> */}
            </div>

         </div>
      </div>
   </main>
    );
}


export default Home;