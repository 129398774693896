import { useEffect } from "react";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { getCartItemQty } from "../../services/CartService";
import { AppContext } from "../../utils/AppProvider";

const HeaderMain = (props) => {
    const { setSearch, setCartQty, cart_qty, is_authenticated, show_mobile_search, setShowMobileSearch } = useContext(AppContext);
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(is_authenticated ==1 ){
            getCartItemQty()
            .then((response) => {
                if(response?.data?.data){
                    setCartQty(response.data.data);
                }
            })
            .catch((error) => {
                console.log("Error", error);
            })
        }else{
            setCartQty(0);
        }
    },[ is_authenticated ]);

    useEffect(()=>{
        // console.log("show_mobile_search CHANGED", show_mobile_search);
    },[show_mobile_search]);

    const handleSearch = (e) => {
        e.preventDefault();
        setShowMobileSearch(false);
        navigate(`/products`);
    } 

    return (
        <div className="header-main header-wrapper">
            <div className="container">
                <div className="column column-left">
                    <div className="header-buttons hide-desktop">
                        <div className="header-canvas button-item">
                            <Link onClick={() => props.setMenu(!props.showMenu)} href="/">
                                <i className="klbth-icon-menu-thin"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="site-brand">
                        <Link to="/" title="Bacola – Grocery Market and Food Theme" >
                            <img className="desktop-logo hide-mobile img-fluid" src="/images/kw-logo.png"
                                alt="Bacola – Grocery Market and Food Theme" width={50} style={{height:'auto'}}/>

                            <img className="mobile-logo hide-desktop img-fluid" src="/images/kw-logo.png"
                                alt="Bacola – Grocery Market and Food Theme" />
                            <span className="brand-description">Online Grocery Shopping Center</span>
                        </Link>
                    </div>
                </div>
                <div className="column column-center">
                    {/* <div className="header-location site-location hide-mobile">
                        <a href="#/home-3/#">
                            <span className="location-description">Your Location</span>
                            <div className="current-location">Select a Location</div>
                        </a>
                    </div> */}

                    <div className={"header-search " + (show_mobile_search ? 'active' : '')}>
                        <div
                            className="dgwt-wcas-search-wrapp dgwt-wcas-no-submit woocommerce dgwt-wcas-style-solaris js-dgwt-wcas-layout-classic dgwt-wcas-layout-classic js-dgwt-wcas-mobile-overlay-enabled">
                            <form className="dgwt-wcas-search-form" onSubmit={(e)=> handleSearch(e) } role="search" method="get">
                                <div className="dgwt-wcas-sf-wrapp">
                                    <svg className="dgwt-wcas-ico-magnifier" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 51.539 51.361"
                                    >
                                        <path
                                            d="M51.539,49.356L37.247,35.065c3.273-3.74,5.272-8.623,5.272-13.983c0-11.742-9.518-21.26-21.26-21.26 S0,9.339,0,21.082s9.518,21.26,21.26,21.26c5.361,0,10.244-1.999,13.983-5.272l14.292,14.292L51.539,49.356z M2.835,21.082 c0-10.176,8.249-18.425,18.425-18.425s18.425,8.249,18.425,18.425S31.436,39.507,21.26,39.507S2.835,31.258,2.835,21.082z">
                                        </path>
                                    </svg>
                                    <label className="screen-reader-text" htmlFor="dgwt-wcas-search-input-1">Products search</label>

                                    <div className="item-search close-canvas search-close-hide" onClick={() => { console.log("close") ; setShowMobileSearch(false); setSearch('')}}><i className="klbth-icon-x"></i></div>
                                    <input defaultValue="" onChange={(e) =>  setSearch(e.target.value)} id="dgwt-wcas--search-input" type="search" className="dgwt-wcas-search-inp\ut" name="s"
                                        placeholder="Search for products..." autoComplete="off" />
                                    {/* <div className="dgwt-wcas-preloader"></div> */}

                                    {/* <div className="dgwt-wcas-voice-search"></div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="column column-right">
                    <div className="header-buttons">
                        {
                            is_authenticated ?
                                <div className="header-login button-item bordered">
                                    <Link to="/my-account"> 
                                        <div className="button-icon"><i className="klbth-icon-user"></i></div>
                                    </Link>
                                </div>    
                            :
                                <div className="header-login button-item bordered">
                                    <Link to="/login"> 
                                        <button className="customer-sign">Sign In</button>    
                                    </Link>
                                </div>
                        }
                        

                        

                        <div className="header-cart button-item bordered">
                            <Link to="/cart" >

                                {/* <div className="cart-price">
                                    <span className="woocommerce-Price-amount amount"> <bdi><span className="woocommerce-Price-currencySymbol"></span> View Cart </bdi></span>
                                </div> */}

                                
                                <div className="button-icon">
                                    <i className="klbth-icon-shopping-bag"></i>
                                </div>

                                <span className="cart-count-icon">{cart_qty}</span>



                            </Link>

                            {/* <div className="cart-dropdown hide">
                                <div className="cart-dropdown-wrapper">

                                    <div className="fl-mini-cart-content">


                                        <div className="cart-empty">
                                            <div className="empty-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.028 280.028"
                                                    width="280.028" height="80.028">
                                                    <path className="c-01" d="M35.004 0h210.02v78.758H35.004V0z" fill="#d07c40"></path>
                                                    <path className="c-02"
                                                        d="M262.527 61.256v201.27c0 9.626-7.876 17.502-17.502 17.502H35.004c-9.626 0-17.502-7.876-17.502-17.502V61.256h245.025z"
                                                        fill="#f4b459"></path>
                                                    <path className="c-03"
                                                        d="M35.004 70.007h26.253V26.253L35.004 0v70.007zm183.767-43.754v43.754h26.253V0l-26.253 26.253z"
                                                        fill="#f4b459"></path>
                                                    <path className="c-04"
                                                        d="M61.257 61.256V26.253L17.503 61.256h43.754zm157.514-35.003v35.003h43.754l-43.754-35.003z"
                                                        fill="#e3911c"></path>
                                                    <path className="c-05"
                                                        d="M65.632 105.01c-5.251 0-8.751 3.5-8.751 8.751s3.5 8.751 8.751 8.751 8.751-3.5 8.751-8.751c0-5.25-3.5-8.751-8.751-8.751zm148.764 0c-5.251 0-8.751 3.5-8.751 8.751s3.5 8.751 8.751 8.751 8.751-3.5 8.751-8.751c.001-5.25-3.501-8.751-8.751-8.751z"
                                                        fill="#cf984a"></path>
                                                    <path className="c-06"
                                                        d="M65.632 121.637c5.251 0 6.126 6.126 6.126 6.126 0 39.379 29.753 70.882 68.257 70.882s68.257-31.503 68.257-70.882c0 0 .875-6.126 6.126-6.126s6.126 6.126 6.126 6.126c0 46.38-35.003 83.133-80.508 83.133s-80.508-37.629-80.508-83.133c-.001-.001.874-6.126 6.124-6.126z"
                                                        fill="#cf984a"></path>
                                                    <path className="c-07"
                                                        d="M65.632 112.886c5.251 0 6.126 6.126 6.126 6.126 0 39.379 29.753 70.882 68.257 70.882s68.257-31.503 68.257-70.882c0 0 .875-6.126 6.126-6.126s6.126 6.126 6.126 6.126c0 46.38-35.003 83.133-80.508 83.133s-80.508-37.629-80.508-83.133c-.001 0 .874-6.126 6.124-6.126z"
                                                        fill="#fdfbf7"></path>
                                                </svg>
                                            </div>
                                            <div className="empty-text">No products in the cart.</div>
                                        </div>


                                    </div>



                                    <div className="cart-noticy">
                                        We reduce shipping prices to only 2.49 €! </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderMain;