import FooterBottom from "./FooterBottom";
import FooterContacts from "./FooterContacts";
import FooterIconBoxes from "./FooterIconBoxes";
import FooterSubscribe from "./FooterSubscribe";
import FooterWidget from "./FooterWidgets";

const Footer = () =>{
    return (
        <footer className="site-footer">
            <FooterSubscribe />

            <FooterIconBoxes />

            {/* <FooterWidget /> */}

            <FooterContacts />

            <FooterBottom />
        </footer>
    );
}


export default Footer;