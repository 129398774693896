
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { retriveCategories } from "../services/CategoryService";

const Category = () => {
   const [categories, setcategories] = useState([]);

   useEffect(()=>{
      getCategories()
   },[]);

   const getCategories = async() =>{
      await retriveCategories().then((response)=>{
         if(response.data.data){
            setcategories(response.data.data);
         }
      
     }).catch((error) =>{
         console.log("Error", error.response)
     });
   }

   const hideCategoryDropdown = () =>{
      document.getElementById("all-categories").classList.remove("show")
   }

    return (
        <div className="all-categories locked">
               <a href="" onClick={(e) => {e.preventDefault(); document.getElementById("all-categories").classList.toggle("show")}}  data-toggle="collapse" data-target="#all-categories">
                  <i className="klbth-icon-menu-thin"></i>
                  <span className="text">ALL CATEGORIES</span>

                  <div className="description">TOTAL 63 PRODUCTS</div>
               </a>

               <div className="dropdown-categories collapse" id="all-categories">
                  <ul id="menu-sidebar-menu-1" className="menu-list">
                        {
                           categories.map((category, index) => {
                              if(category.parent_id == null){
                              return (
                                 <li key={index} className={"category-parent menu-item menu-item-type-taxonomy menu-item-object-product_cat" + (categories.filter((el) => el.parent_id == category.id).length > 0 ? ' parent' : '')}>
                                    <Link onClick={()=> hideCategoryDropdown() } to={"/product-category/" + category.slug}>
                                       {/* <i className="klbth-icon-biscuit"></i>  */}
                                       {category.category_name}
                                    </Link>
                                    {
                                       categories.filter((el) => el.parent_id == category.id).length > 0 ?
                                          <ul className="sub-menu">
                                             {categories.map((sub_cat, index) => {
                                                if(sub_cat.parent_id == category.id){
                                                return (

                                                   <li key={index} className={"category-parent  menu-item menu-item-type-taxonomy menu-item-object-product_cat" }>
                                                      <Link onClick={()=> hideCategoryDropdown()} to={"/product-category/" + category.slug  + "/" + sub_cat.slug}>
                                                         {sub_cat.category_name}
                                                      </Link>
                                                   </li>
                                                )}
                                             })}
                                          </ul>

                                          : null
                                    }
                                 </li>
                              )}
                           })
                        }

                     {/* <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/?on_sale=onsale"><i className="bottom"></i> Value of the
                        Day</a></li>
                     <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/?filter_cat=58,35,31"><i className="bottom"></i> Top 100
                        Offers</a></li>
                     <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/"><i className="bottom"></i> New Arrivals</a></li> */}
                  </ul>
               </div>

            </div>
    );
}


export default Category;