import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const sendContactMessage = async(contact) => {
    return new Promise(function (resolve, reject) {
        axios.post('/customer/send-contact-message',{...contact})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { sendContactMessage };