import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav className="canvas-menu canvas-primary vertical">
            <ul id="menu-menu-1" className="menu">
                <li
                    className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent">
                    <Link to="/">Home</Link>
                    
                    {/* <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span> */}
                </li>
                
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><Link
                    to="/product-category/edibles"><i
                        className="klbth-icon-meat"></i> Edibles</Link></li>
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><Link
                    to="/product-category/flower-pre-rolls"><i
                        className="klbth-icon-forumbee"></i> Flower & Pre-Rolls</Link></li>
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><Link
                    to="/product-category/tinctures"><i className="klbth-icon-biscuit"></i>
                    Tinctures</Link></li>
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><Link
                    to="/product-category/vape"><i className="klbth-icon-cup"></i>
                    Vape</Link></li>
             
                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><Link
                    to="/contact">Contact</Link></li>
            </ul>
        </nav>
    );
}


export default Navbar;