import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { retriveCategories } from "../../services/CategoryService";



const Category = () => {

    const [categories,setCategories] = useState();
    const [firstCategory,setFirstCategory] = useState();

    useEffect(()=>{
        getCategories();
    },[ ]);

    const getCategories = async() => {
        await retriveCategories(9).then((response)=>{
           if(response.data.data){
              setCategories(response.data.data.filter((cat,index) => index != 0));
              setFirstCategory(response.data.data[0]);
           }
        
       }).catch((error) =>{
           console.log("Error", error.response)
       });
     }


    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-0d81a18 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="0d81a18" data-element_type="section">
            <div className="elementor-container elementor-column-gap-extended">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-936f9c9"
                    data-id="936f9c9" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-dacf948 elementor-widget elementor-widget-bacola-product-categories"
                            data-id="dacf948" data-element_type="widget"
                            data-widget_type="bacola-product-categories.default">
                            <div className="elementor-widget-container">
                                <div className="site-module module-category style-1">
                                    <div className="module-body">
                                        <div className="categories">
                                            <div className="first">
                                                <div className="category">
                                                    <div className="category-image"> <Link
                                                        to={"/product-category/" + firstCategory?.slug}><img
                                                            src="/images/baverages-1.jpg" alt="Beverages" /></Link></div>
                                                    <div className="category-detail">
                                                        <h3 className="entry-category"><a
                                                            href={"/product-category/" + firstCategory?.slug}>{firstCategory?.category_name}</a>
                                                        </h3>
                                                        {/* <div className="category-count">11 Items</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="categories-wrapper">
                                                {
                                                    categories?.length > 0 ? categories.map((cat, index) => {
                                                        return (
                                                            <div key={index} className="category">
                                                                <div className="category-image"><Link
                                                                    to={"/product-category/" + cat.slug}><img
                                                                        src="/images/biscuitssnacks-1.jpg"
                                                                        alt="Biscuits &amp; Snacks" /></Link></div>
                                                                <div className="category-detail">
                                                                    <h3 className="entry-category"><Link
                                                                        to={"/product-category/" + cat.slug}>{cat.category_name} </Link></h3>
                                                                    {/* <div className="category-count">6 Items</div> */}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                        : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Category;