import './App.css';
// import './assets/css/back-to-top.css';
// import './assets/css/base.css';
// import './assets/css/base-rtl.css';
// import './assets/css/base.css';
// import './assets/css/bootstrap.min.css';
// import './assets/css/data.css';
// import './assets/css/product-data-video.css';
// import './assets/css/select2.min.css';
// import './assets/css/single-products-navigation.css';
// import './assets/css/style.css';
// import './assets/css/theme.css';


import './assets/css/b2b-custom.css';
import './assets/css/animate.css';
import './assets/css/back-to-top.css';

import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap.min.css.map';
import './assets/css/custom-theme.css';
import './assets/css/elementor-icons.min.css';
import './assets/css/free-shipping.css';
import './assets/css/frontend.css';
import './assets/css/frontend.min.css';
import './assets/css/hint.min.css';
import './assets/css/index.css';
import './assets/css/index(1).css';
import './assets/css/notice-ajax.css';
import './assets/css/perfect-scrollbar.min.css';
import './assets/css/public.min.css';
import './assets/css/responsive.css';
import './assets/css/select2.css';
import './assets/css/single-ajax.css';
import './assets/css/style.css';
import './assets/css/style.min.css';
import './assets/css/style.min(1).css';
import './assets/css/styles.css';
import './assets/css/swatches.css';
import './assets/css/wc-blocks-style.css';
import './assets/css/wc-blocks-vendors-style.css';
import './assets/css/webfont.min.css';
import './assets/css/base.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import Index from './layout/Index';

// require('dotenv').config()

function App() {
  return (
    <Router>
      <ToastContainer pauseOnFocusLoss={false} />
      <Index></Index>
    </Router>
  );
}

export default App;
