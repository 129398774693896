import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import StoreNotice from "../../common/StoreNotice";
import Canvas from "../Canvas/Canvas";
import CanvasFooter from "../Canvas/CanvasFooter";
import Location from "../Canvas/Location";
import Navbar from "../Canvas/Navbar";
import HeaderMain from "./HeaderMain";
import HeaderMobileNavbar from "./HeaderMobileNavbar";
import HeaderNavbar from "./HeaderNavbar";
import HeaderTop from "./HeaderTop";

const Header = () => {
   const [showMenu, setShowMenu] = useState(false);
   const location =useLocation();

    useEffect(() => {
        setShowMenu(false);
    },[ location.pathname]);
    
   return (
      <>
         <Canvas showMenu={showMenu} setMenu={(val) => setShowMenu(val)} />

         <StoreNotice />

         <header id="masthead" className="site-header desktop-shadow-disable mobile-shadow-enable mobile-nav-enable" itemScope="itemscope" itemType="http://schema.org/WPHeader">


            <HeaderTop />

            <HeaderMain showMenu={showMenu} setMenu={(val) => setShowMenu(val)} />

            <HeaderNavbar />

            <HeaderMobileNavbar />

         </header>
      </>
   );
}

export default Header;