import { Link } from "react-router-dom";

const FooterBottom = () => {
    return (
        <div className="footer-bottom border-enable">
            <div className="container">
                <div className="site-copyright">
                    <p>Copyright 2022 © {process.env.REACT_APP_PROJECT}. All rights reserved. Powered by N2N Solution.</p>
                </div>

                <nav className="site-menu footer-menu">
                    <ul id="menu-footer-menu" className="menu">
                        <li id="menu-item-1889"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-1889">
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li id="menu-item-2066"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2066"><Link to="/terms-and-conditions/">Terms and Conditions</Link></li>
                        {/* <li id="menu-item-1844"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1844"><Link to="#/home-3/#">Cookie</Link></li> */}
                    </ul>
                </nav>
                <div className="site-payments"><a href="#/home-3/#"><img
                    src="/images/payments.jpg" alt="payment" /></a></div>
            </div>
        </div>
    );
}


export default FooterBottom;