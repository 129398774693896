import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart, retriveRecentViewedItems } from "../services/ItemService";
import { convertToSlug, isLogin } from "../utils/constant";
import { AppContext } from "../utils/AppProvider";
import { useContext } from "react";

const RecentViewProducts = (props) => {
  const [items, setItems] = useState([]);
  const [wishlistItems, setwishlistItems] = useState([]);
  const { setCartQty, cart_qty} = useContext(AppContext);

  useEffect(() => {
    getWishlist();
  }, []);

  useEffect(() => {
    let viewd_items = localStorage.getItem('recent-view-product');

    if (viewd_items != null && props.prod_id != undefined) {
      viewd_items = JSON.parse(viewd_items);
      let ids = viewd_items.join(",");

      retriveRecentViewedItems(ids, props.prod_id).then((response) => {
        if (response.data.data) {
          setItems(response.data.data);
        }
      }).catch((error) => {
        console.log("Error", error.response)
      });
    }
  }, [props.prod_id]);

  const getWishlist = async () => {
    let items = await localStorage.getItem('wishlist_items')

    if (items != null) {
      items = JSON.parse(items);
      let wishlist_item_ids = items.map((_item) => { return _item.id })
      setwishlistItems(wishlist_item_ids);
    }
  }

  const toggleWishlist = async (item) => {
    let items = await localStorage.getItem('wishlist_items')

    let date = new  Date();
    date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " +  date.getFullYear();

    if (items != null) {
      items = JSON.parse(items);
      let ids = items.map(item => item['id']);

      if (ids.includes(item.id)) {
        let wishlist_items = items.filter((_item) => _item.id != item.id)
        let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })

        setwishlistItems(wishlist_item_ids);
        localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
        toast("Item Removed from Wishlist", { type: 'success' });
      } else {
        item['wishlist_added_date'] = date;
        items.push(item)
        setwishlistItems([...wishlistItems, item.id])
        localStorage.setItem('wishlist_items', JSON.stringify(items));
        toast("Item added into Wishlist", { type: 'success' });
      }
    } else {
      let temp = [];
      item['wishlist_added_date'] = date;
      temp.push(item)
      setwishlistItems([item.id])
      localStorage.setItem('wishlist_items', JSON.stringify(temp));

      toast("Item added into Wishlist", { type: 'success' });
    }
  }

  const handleAddToCart = (item) => {
    if (isLogin()) {
      let item_id = item.id;
      addItemToCart(item_id)
        .then((response) => {
          setCartQty(parseInt(cart_qty) + 1);
          toast('Item Added To Cart Successfully.', { type: 'success' })
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: 'error' })
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: 'error' })
          else
            toast("Something went wrong", { type: 'error' });
        })
    }else{
      toast('Please login to add item to cart',{type:'error'});
   }
  }

  return (
    <>
      {items.length > 0 ?


        <section className="klb-module site-module recently-viewed">
          <div className="container">
            <div className="klb-title module-header">
              <h4 className="entry-title">Recently Viewed Products</h4>
            </div>
            <div className="products column-4 mobile-column-2 align-inherit">
              {
                items.map((item, index) => {
                  return (
                    <div key={index} className="product type-product post-383 status-publish first instock product_cat-meats-seafood has-post-thumbnail sale shipping-taxable purchasable product-type-simple">

                      <div className="product-wrapper product-type-1">
                        <div className="thumbnail-wrapper">
                          <div className="product-badges"><span className="badge style-1 onsale">16%</span>
                          </div>
                          <Link to={"/product/" + item.id + "/" + convertToSlug(item.item_name)}>
                            <img src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"} alt={item?.item_name} />
                          </Link>
                          <div className="product-buttons">
                            <a href="383" className="detail-bnt quick-view-button">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                </path>
                              </svg></a>
                            <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" data-tinvwl_product_id="383" onClick={() => toggleWishlist(item)}>
                              <div className="tinv-wishlist-clear"></div><a role="button" tabIndex="0" aria-label="Add to Wishlist" className={"tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')} data-tinv-wl-list="[]" data-tinv-wl-product="383" data-tinv-wl-productvariation="0" data-tinv-wl-productvariations="[0]" data-tinv-wl-producttype="simple" data-tinv-wl-action="add">
                                <span className="tinvwl_add_to_wishlist-text">Add to Wishlist</span></a>
                              <div className="tinv-wishlist-clear"></div>
                              <div className="tinvwl-tooltip">Add to Wishlist</div>
                            </div>
                          </div>
                        </div>
                        <div className="content-wrapper">
                          <h3 className="product-title">
                            <Link to={"/product/" + item.id + "/" + convertToSlug(item.item_name)} title={item.item_name}>{item.item_name}</Link>
                          </h3>
                          <div className="product-meta">
                            {item.stock > 0 ?
                              <div className="product-available in-stock"> In Stock</div>
                              : <div className="product-available out-of-stock"> Out Of Stock</div>
                            }
                          </div>
                          <div className="product-rating">
                            <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                              <span style={{ width: '100%' }}>Rated <strong className="rating">5.00</strong>
                                out of 5</span>
                            </div>
                            <div className="count-rating">1 <span className="rating-text">Ratings</span>
                            </div>
                          </div>
                          <div className="">
                              <div className="" ><span style={{fontSize:"12px"}}>Item Code: {item.item_code}</span></div>
                              <div className="" ><span style={{fontSize:"12px"}}>Barcode: {item.custom_barcode}</span></div>
                          </div>
                          <span className="price"><del aria-hidden="true"><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                            <ins><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price}</bdi></span></ins></span>
                          <div className="product-fade-block">
                            <div className="product-button-group cart-with-quantity ">
                              <div className="quantity ajax-quantity">
                                <div className="quantity-button minus"><i className="klbth-icon-minus"></i></div><input onChange={() => { }} type="text" className="input-text qty text" name="quantity" step="1" min="" max="65" value="1" title="Menge" size="4" inputMode="numeric" />
                                <div className="quantity-button plus"><i className="klbth-icon-plus"></i></div>
                              </div> <a href="" onClick={(e) => { e.preventDefault(); handleAddToCart(item) }} data-quantity="1" className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="383" data-product_sku="A9UKLA7" aria-label="Add “Encore Seafoods Stuffed Alaskan Salmon” to your cart" rel="nofollow">Add to cart</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product-content-fade border-info" style={{ marginBottom: '-49px' }}>
                      </div><button className="woosc-btn woosc-btn-383 " data-id="383" data-product_name="Encore Seafoods Stuffed Alaskan Salmon" data-product_image="#/wp-content/uploads/2021/04/product-image-48-150x150.jpg">Compare</button>
                      <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-after-add-to-cart tinvwl-loop-button-wrapper" data-tinvwl_product_id="383">
                        <div className="tinv-wishlist-clear"></div><a role="button" tabIndex="0" aria-label="Add to Wishlist" className="tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-after tinvwl-loop" data-tinv-wl-list="[]" data-tinv-wl-product="383" data-tinv-wl-productvariation="0" data-tinv-wl-productvariations="[0]" data-tinv-wl-producttype="simple" data-tinv-wl-action="add"><span className="tinvwl_add_to_wishlist-text">Add to Wishlist</span></a>
                        <div className="tinv-wishlist-clear"></div>
                        <div className="tinvwl-tooltip">Add to Wishlist</div>
                      </div>
                    </div>
                  );
                })
              }

            </div>
          </div>
        </section>
        : null}
    </>
  );
}

export default RecentViewProducts;