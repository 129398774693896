const FooterIconBoxes = () =>{
    return (
        <div className="footer-iconboxes">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-6 col-lg-3">
                            <div className="iconbox">
                                <div className="iconbox-icon"><i className="klbth-icon-milk-box"></i></div>
                                <div className="iconbox-detail">
                                    <span>Everyday fresh products</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-3">
                            <div className="iconbox">
                                <div className="iconbox-icon"><i className="klbth-icon-delivery-truck-2"></i></div>
                                <div className="iconbox-detail">
                                    <span>Free delivery for order over $70
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-3">
                            <div className="iconbox">
                                <div className="iconbox-icon"><i className="klbth-icon-discount-outline"></i></div>
                                <div className="iconbox-detail">
                                    <span>Daily Mega Discounts</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-3">
                            <div className="iconbox">
                                <div className="iconbox-icon"><i className="klbth-icon-dollar"></i></div>
                                <div className="iconbox-detail">
                                    <span>Best price on the market</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
    );
}


export default FooterIconBoxes;