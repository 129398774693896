import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveOrders } from "../../services/OrderService";
import { stateByIds } from "../../services/StateService";
import { convertToSlug } from "../../utils/constant";
import Navigation from "./Navigation";

const Orders = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  const [singleOrder, setSingleOrder] = useState({});
  const [orderStates, setOrderStates] = useState([]);
  let date = '';
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowMenu(false);
  },[location.pathname]);

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(async() => {
    if(singleOrder){
      let ids = [singleOrder.billing_address?.state_id,singleOrder.shipping_address?.state_id].join(",");
      await stateByIds(ids).then((response) => {

        if (response?.data?.data) setOrderStates(response.data.data);
      }).catch((error) => {
          toast("Something went wrong", { type: 'error' });
      });
    }
  },[singleOrder]);

  const getOrders = async () => {
    await retriveOrders().then((response) => {
      if (response?.data?.data) setOrders(response.data.data);
    }).catch((error) => {
      if (error.response.status == 422)
        toast(error.response.data.error, { type: 'error' })
      else if (error.response.status == 500)
        toast(error.response.data.message, { type: 'error' })
      else
        toast("Something went wrong", { type: 'error' });
    });
  }

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="shop-content my-account-page">
            <div className="container">
              <nav className="woocommerce-breadcrumb">
                <ul>
                  <li>
                    <a href="#https://klbtheme.com/bacola">Home</a>
                  </li>
                  <li>
                    <a href="#https://klbtheme.com/bacola/my-account/">My account</a>
                  </li>
                  <li>Orders</li>
                </ul>
              </nav>
              <div className="woocommerce">
                <div className="row content-wrapper sidebar-right">
                  <div className="col-12 col-md-12 col-lg-12 content-primary">
                    <div className="my-account-wrapper">
                      {" "}
                      {/* my-account-wrapper be closed in myaccount.php */}
                      <div className="my-account-navigation">
                        <div className="account-toggle-menu" onClick={() => setShowMenu(!showMenu)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="2.3" y1={12} x2="21.8" y2={12} />
                            <line x1="2.3" y1={6} x2="21.8" y2={6} />
                            <line x1="2.3" y1={18} x2="21.8" y2={18} />
                          </svg>
                          Navigation{" "}
                        </div>
                        {/* account-toggle-menu */}
                        <Navigation showMenu={showMenu} setMenu={(val) => setShowMenu(val)} />
                      </div>
                      <div className="woocommerce-MyAccount-content">
                        <div className="woocommerce-notices-wrapper" />
                        {
                          (!showOrder) ?

                            <>
                              {
                                orders.map((order, index) => {
                                  return (
                                    <div className="klb-orders-style1">
                                      <table className="woocommerce-orders-table woocommerce-MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table">
                                        <thead>
                                          <tr>
                                            <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-number">
                                              <span className="nobr">Order</span>
                                            </th>
                                            <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-date">
                                              <span className="nobr">Date</span>
                                            </th>
                                            <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-status">
                                              <span className="nobr">Status</span>
                                            </th>
                                            <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-total">
                                              <span className="nobr">Total</span>
                                            </th>
                                            <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-actions">
                                              <span className="nobr">Actions</span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr className="woocommerce-orders-table__row woocommerce-orders-table__row--status-processing order">
                                            <td
                                              className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-number"
                                              data-title="Order"
                                            >
                                              <a href="" onClick={(e) => { e.preventDefault(); setSingleOrder(order); setShowOrder(!showOrder) }}>
                                                #order-{order.id}
                                              </a>
                                            </td>
                                            <td
                                              className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-date"
                                              data-title="Date"
                                            >
                                              <time dateTime="2022-12-02T10:48:36+00:00">{date = new Date(order.created_at).toLocaleString('default', { month: 'long' })
                                                + " " + new Date(order.created_at).getDate()
                                                + " " + new Date(order.created_at).getFullYear()} </time>
                                            </td>
                                            <td
                                              className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-status"
                                              data-title="Status"
                                            >
                                              {order.order_status}
                                            </td>
                                            <td
                                              className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-total"
                                              data-title="Total"
                                            >
                                              <span className="woocommerce-Price-amount amount">
                                                <span className="woocommerce-Price-currencySymbol">$</span>{order.total}
                                              </span>{" "}
                                              {/* for 3 items */}
                                            </td>
                                            <td
                                              className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-actions"
                                              data-title="Actions"
                                            >
                                              <a
                                                href=""
                                                className="woocommerce-button button view"
                                                onClick={(e) => { e.preventDefault(); setSingleOrder(order); setShowOrder(!showOrder) }}
                                              >
                                                View
                                              </a>{" "}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div className="order-list">
                                        {
                                          order?.line_items.map((item, index) => {
                                            return (
                                              <div className="order-item">
                                                <div className="product-name">
                                                  <Link to={"/product/" + item.item_id + "/" + convertToSlug(item.item.item_name)}>
                                                    {item.item?.item_name}
                                                  </Link>{" "}
                                                  <strong className="product-quantity">×&nbsp; {item.quantity}</strong> <span className={"badge"+ (item.fulfillment_status ? ' bdg-success' : '')}>{item.fulfillment_status ?? 'Not Fulfilled'}</span> 
                                                </div>
                                                <div className="product-thumbnail">
                                                  <img src={item?.item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.item.image.url : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"} />
                                                </div>
                                              </div>
                                            )
                                          })
                                        }

                                      </div>
                                    </div>
                                  );
                                })
                              }
                              {
                                orders.length <= 0 ?
                                <h5 style={{fontWeight:'bold',textAlign:'center'}}>You have not placed any order.</h5>
                                : null
                              }
                            </>


                            :
                            <>
                              <p>
                                Order #<mark className="order-number">order-{singleOrder.id}</mark> was placed on{" "}
                                <mark className="order-date">{date = new Date(singleOrder.created_at).toLocaleString('default', { month: 'long' })
                                                + " " + new Date(singleOrder.created_at).getDate()
                                                + " " + new Date(singleOrder.created_at).getFullYear()}</mark> and currently status is{" "}
                                <mark className="order-status">{singleOrder.order_status}</mark>. 
                                <button type="button" className="btn-small w-auto" onClick={() => {setSingleOrder([]); setShowOrder(false)}}> Go Back</button>
                              </p>
                              <section className="woocommerce-order-details">
                                <h2 className="woocommerce-order-details__title">Order details</h2>
                                <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                                  <thead>
                                    <tr>
                                      <th className="woocommerce-table__product-name product-name">
                                        Product
                                      </th>
                                      <th className="woocommerce-table__product-table product-total">
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      singleOrder.line_items?.map((item,index) => {
                                        return (
                                          <tr className="woocommerce-table__line-item order_item">
                                      <td className="woocommerce-table__product-name product-name">
                                        <Link to={"/product/" + item.item_id + "/" + convertToSlug(item.item.item_name)}>
                                        {item.item?.item_name}
                                        </Link>{" "}
                                        <strong className="product-quantity">×&nbsp;{item.quantity}</strong>{" "} <span className={"badge"+ (item.fulfillment_status ? ' bdg-success' : '')}>{item.fulfillment_status ?? 'Not Fulfilled'}</span> 
                                      </td>
                                      <td className="woocommerce-table__product-total product-total">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">$</span>{item.subtotal}
                                          </bdi>
                                        </span>{" "}
                                      </td>
                                    </tr>
                                        );
                                      })
                                    }
                                    
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th scope="row">Subtotal:</th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">$</span>{singleOrder.subtotal}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Tax:</th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">$</span>{singleOrder.tax?.toFixed(2)}
                                        </span>
                                        {/* &nbsp;<small className="shipped_via">via Flat rate</small> */}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Payment method:</th>
                                      <td>Cash on delivery</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Total:</th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">$</span>{singleOrder.total}
                                        </span>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </section>
                              <section className="woocommerce-customer-details">
                                <section className="woocommerce-columns woocommerce-columns--2 woocommerce-columns--addresses col2-set addresses">
                                  <div className="woocommerce-column woocommerce-column--1 woocommerce-column--billing-address col-1 cst-50-w">
                                    <h2 className="woocommerce-column__title">Billing address</h2>
                                    <address>
                                      {singleOrder.billing_address.name}
                                      <br />
                                      {singleOrder.billing_address.company_name}
                                      <br />
                                      {singleOrder.billing_address.address}
                                      <br />
                                      {singleOrder.billing_address.city}
                                      <br />
                                      {orderStates[singleOrder.billing_address.state_id]}
                                      <br />
                                      {singleOrder.billing_address.pincode}
                                      
                                      <p className="woocommerce-customer-details--phone">
                                        {singleOrder.phone}
                                      </p>
                                      <p className="woocommerce-customer-details--email">
                                        {singleOrder.email}
                                      </p>
                                    </address>
                                  </div>
                                  {/* /.col-1 */}
                                  <div className="woocommerce-column woocommerce-column--2 woocommerce-column--shipping-address col-2 cst-50-w">
                                    <h2 className="woocommerce-column__title">Shipping address</h2>
                                    <address>
                                      {singleOrder.shipping_address.name}
                                      <br />
                                      {singleOrder.shipping_address.company_name}
                                      <br />
                                      {singleOrder.shipping_address.address}
                                      <br />
                                      {singleOrder.shipping_address.city}
                                      <br />
                                      {orderStates[singleOrder.shipping_address.state_id]}
                                      <br />
                                      {singleOrder.shipping_address.pincode}
                                    </address>
                                  </div>
                                  {/* /.col-2 */}
                                </section>
                                {/* /.col2-set */}
                              </section>
                            </>
                        }
                      </div>
                    </div>{" "}
                    {/* my-account-wrapper closed in navigation.php */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* homepage-content */}
      </div>
      {/* site-content */}
    </main>

  );
}

export default Orders;