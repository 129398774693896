import reducer from "./reducer";
import { createContext, useReducer } from "react";

//Context and Provider
export const AppContext = createContext();

const Provider = ({ children }) => {
     
    const initialState = {
        search: '',
        cart_qty: 0,
        show_mobile_search: false,
        is_authenticated: localStorage.getItem('is_authenticated'),
    };
    
    const actions = {
        SET_SEARCH: "SET_SEARCH",
        SET_CART_QTY: "SET_CART_QTY",
        IS_AUTHENCTICATED: "IS_AUTHENTICATED",
        SHOW_MOBILE_SEARCH: "SHOW_MOBILE_SEARCH",
    };

  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    search: state.search,
    cart_qty: state.cart_qty,
    is_authenticated: state.is_authenticated,
    show_mobile_search: state.show_mobile_search,

    setSearch: (query) => {
      dispatch({ type: actions.SET_SEARCH, query });
    },

    setCartQty: (qty) => {
      dispatch({ type: actions.SET_CART_QTY, qty });
    },
    
    setIsAuthenticated: (value) => {
      dispatch({ type: actions.IS_AUTHENCTICATED, value });
    },
    
    setShowMobileSearch: (value) => {
      dispatch({ type: actions.SHOW_MOBILE_SEARCH, value });
    }
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};


export default Provider;