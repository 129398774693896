import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import RecentViewProducts from "../../common/RecentViewProducts";
import { addItemToCart, retriveItem } from "../../services/ItemService";
import { Link } from "react-router-dom";
import RelatedProducts from "./RelatedProducts";
import { toast } from "react-toastify";
import { isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const Product = () => {
    const [item,setItem] = useState({});
    const [cartQuantity,setCartQuantity] = useState(1);
    const [wishlistItems,setwishlistItems] = useState([]);
    const { setCartQty, cart_qty} = useContext(AppContext);

    const params = useParams();

    useEffect(()=>{
        getWishlist();
        getItem();
        setRecentViewProduct();
        setCartQuantity(1);
    },[ params.id]);


    const getItem = async() =>{
        let item_id = params.id
        await retriveItem(item_id).then((response)=>{
            if(response.data.data){
               setItem(response.data.data);
            }
        }).catch((error) =>{
            console.log("Error", error.response)
        });
    }

    const getWishlist = async () => {
        let items = await localStorage.getItem('wishlist_items')
        
        if(items != null){
              items = JSON.parse(items);
              let wishlist_item_ids = items.map((_item) => {return _item.id})
              setwishlistItems(wishlist_item_ids);
        }
     }
  
     const toggleWishlist = async(item) =>{
        let items = await localStorage.getItem('wishlist_items')

        let date = new  Date();
        date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " +  date.getFullYear();
        
        if(items != null){
           items = JSON.parse(items);
           let ids = items.map(item => item['id']);
  
           if(ids.includes(item.id)){
              let wishlist_items = items.filter((_item) => _item.id != item.id)
              let wishlist_item_ids = wishlist_items.map((_item) => {return _item.id})
  
              setwishlistItems(wishlist_item_ids);
              localStorage.setItem('wishlist_items',JSON.stringify(wishlist_items)); 
              toast("Item Removed from Wishlist",{type:'success'});
           }else{
              item['wishlist_added_date'] = date;
              items.push(item)
              setwishlistItems([...wishlistItems,item.id])
              localStorage.setItem('wishlist_items',JSON.stringify(items)); 
              toast("Item added into Wishlist",{type:'success'});
           }
        }else{
           let temp = [];
           item['wishlist_added_date'] = date;
           temp.push(item)
           setwishlistItems([item.id])
           localStorage.setItem('wishlist_items',JSON.stringify(temp));
  
           toast("Item added into Wishlist",{type:'success'});
        }
     }

    const setRecentViewProduct = () =>{
        let items = localStorage.getItem('recent-view-product');
        
        if(items != null){
            items = JSON.parse(items);
            
            if(!items.includes(params.id)){
                items.push(params.id);
                localStorage.setItem('recent-view-product', JSON.stringify(items));
            }
        }else{
            items = [ params.id ];
            localStorage.setItem('recent-view-product', JSON.stringify(items));
        }
    }

    const handleAddToCart = (item) => {
        if(isLogin()){
           let item_id = item.id;
           addItemToCart(item_id, cartQuantity)
              .then((response) => {
                setCartQty(parseInt(cart_qty) + cartQuantity);
                setCartQuantity(1);
                 toast('Item Added To Cart Successfully.',{type: 'success'})
              })
              .catch((error) => {
                 console.log("Error", error);
                 if(error.response.status == 422)
                      toast(error.response.data.message,{type: 'error'})
                  else if(error.response.status == 500)
                      toast(error.response.data.message,{type: 'error'})
                  else    
                      toast("Something went wrong", {type: 'error'});
              })
        }else{
            toast('Please login to add item to cart',{type:'error'});
         }
     }

    return (
        <main id="main"  className="site-primary">
            <div  className="site-content">
                <div  className="homepage-content">

                    <div  className="shop-content single-content single-gray">
                        <div  className="container">

                            <div  className="klb-product-nav-wrapper">
                                <nav  className="woocommerce-breadcrumb">
                                    <ul>
                                        <li><Link href="/">Home</Link></li>
                                        <li><Link to={"/product-category/" + item?.category?.slug }> { item?.category?.category_name} </Link></li>
                                        <li>{item.item_name}</li>
                                    </ul>
                                </nav>


                                <div  className="klb-products-nav">

                                    <div  className="product-btn product-prev">
                                        <Link to="#/product/angies-boomchickapop-sweet-salty-kettle-corn/"><span  className="product-btn-icon"></span></Link>


                                        <Link to="#/shop/"  className="klb-back-btn"></Link>

                                    </div>

                                </div>

                                <div  className="single-wrapper">

                                    {/* <script type="text/javascript">
                                        var loadmore = {
                                            "ajaxurl": "#/wp-admin/admin-ajax.php",
                                            "current_page": 1,
                                            "per_page": 0,
                                            "max_page": 0,
                                            "cat_id": "",
                                            "filter_cat": "",
                                            "layered_nav": [],
                                            "on_sale": [],
                                            "orderby": "",
                                            "shop_view": "",
                                            "min_price": "",
                                            "max_price": "",
                                            "no_more_products": "No More Products",
                                            "is_search": "",
                                            "s": "",
                                        }
                                    </script> */}


                                    <div  className="woocommerce-notices-wrapper"></div>
                                    <div id="product-430"  className="product type-product post-430 status-publish first instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple">

                                        <div  className="product-header">
                                            <h1  className="product_title entry-title"> { item.item_name } </h1>
                                            <div  className="product-meta top">

                                                <div  className="product-brand">
                                                    <table  className="woocommerce-product-attributes shop_attributes">
                                                        <tbody>
                                                            <tr  className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_brands">
                                                                <th  className="woocommerce-product-attributes-item__label">
                                                                    Brands</th>
                                                                <td  className="woocommerce-product-attributes-item__value">
                                                                    <p> {item?.brand?.brand_name} </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>


                                                <div  className="woocommerce-product-rating product-rating">
                                                    <div  className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                        <span style={{width:'80%'}}>Rated <strong  className="rating">4.00</strong>
                                                            out of 5 based on <span  className="rating">1</span> customer
                                                            rating</span>
                                                    </div>
                                                    <div  className="count-rating">
                                                        <Link to="#reviews"  className="woocommerce-review-link" rel="nofollow"><span  className="count">1</span> review</Link>
                                                    </div>
                                                </div>


                                                <div  className="sku-wrapper">
                                                    <span>SKU:</span>
                                                    <span  className="sku">{ item.sku } </span>
                                                </div>


                                            </div>

                                        </div>

                                        <div  className="product-content">
                                            <div  className="row">
                                                <div  className="col col-12 col-lg-5 product-images">
                                                    <div  className="woocommerce-product-gallery  woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images" data-columns="4" style={{opacity: '1', transition: 'opacity 0.25s ease-in-out 0s'}}>
                                                        <div  className="product-badges"><span  className="badge style-1 onsale">23%</span><span  className="badge style-1 recommend">recommended</span></div>
                                                        <div  className="slider-wrapper">
                                                            <div  className="flex-viewport" style={{overflow: 'hidden', position: 'relative', height: '325px'}}>
                                                                <figure  className="woocommerce-product-gallery__wrapper" style={{width: '600%', transitionDuration: '0s', transform: 'translate3d(0px, 0px, 0px)'}}>
                                                                    <div data-thumb="/images/product-image-62-150x150.jpg" data-thumb-alt=""  className="woocommerce-product-gallery__image flex-active-slide" style={{width: '336.667px', marginRight: '0px', float: 'left', display: 'block'}}>
                                                                        <Link to="#/images/product-image-62.jpg"><img width="600" height="540" src={item?.images?.length > 0 ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.images[0] : process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png"}  className="wp-post-image" alt="" loading="lazy" title="product-image" data-caption="" data-src="/images/product-image-62.jpg" data-large_image="#/wp-content/uploads/2021/04/product-image-62.jpg" data-large_image_width="1024" data-large_image_height="921" draggable="false" /></Link>
                                                                    </div>
                                                                    <div data-thumb="#/wp-content/uploads/2021/04/product-image2-47-150x150.jpg" data-thumb-alt=""  className="woocommerce-product-gallery__image" style={{width: '336.667px', marginRight: '0px', float: 'left', display: 'block'}}>
                                                                        <Link to="#/wp-content/uploads/2021/04/product-image2-47.jpg"><img width="600" height="540" src="/images/product-image2-47-600x540.jpg"  className="" alt="" loading="lazy" title="product-image2" data-caption="" data-src="/images/product-image2-47.jpg" data-large_image="/images/product-image2-47.jpg" data-large_image_width="1024" data-large_image_height="921"  sizes="(max-width: 600px) 100vw, 600px" draggable="false" /></Link>
                                                                    </div>
                                                                    <div data-thumb="#/wp-content/uploads/2021/04/product-image3-35-150x150.jpg" data-thumb-alt=""  className="woocommerce-product-gallery__image" style={{width: '336.667px', marginRight: '0px', float: 'left', display: 'block'}}>
                                                                        <Link to="#/wp-content/uploads/2021/04/product-image3-35.jpg"><img width="600" height="540" src={process.env.REACT_APP_BASE_URL + "/storage/products/product-image3-35-600x540.jpg"}  className="" alt="" loading="lazy" title="product-image3" data-caption="" data-src="/images/product-image3-35.jpg" data-large_image="#/wp-content/uploads/2021/04/product-image3-35.jpg" data-large_image_width="1024" data-large_image_height="921" srcSet="#/wp-content/uploads/2021/04/product-image3-35-600x540.jpg 600w, #/wp-content/uploads/2021/04/product-image3-35-60x54.jpg 60w, #/wp-content/uploads/2021/04/product-image3-35-64x58.jpg 64w, #/wp-content/uploads/2021/04/product-image3-35-300x270.jpg 300w, #/wp-content/uploads/2021/04/product-image3-35-768x691.jpg 768w, #/wp-content/uploads/2021/04/product-image3-35.jpg 1024w" sizes="(max-width: 600px) 100vw, 600px" draggable="false" /></Link>
                                                                    </div>
                                                                </figure>
                                                            </div>
                                                            <ol  className="flex-control-nav flex-control-thumbs product-thumbnails slick-initialized slick-slider">
                                                                <div  className="slick-list draggable">
                                                                    <div  className="slick-track" style={{opacity: '1', width: '174px', transform: 'translate3d(0px, 0px, 0px)'}}>
                                                                        {
                                                                            item?.images?.map((image,index) => {
                                                                                return (
                                                                                    <li key={index} className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{width: '50px'}} tabIndex="0">
                                                                                        <img src={image ? process.env.REACT_APP_BASE_URL + '/storage/products/' + image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}  className="flex-active" draggable="false" width="150" height="150" />
                                                                                    </li>            
                                                                                );
                                                                            })
                                                                        }
                                                                        
                                                                       {/*  <li  className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{width: '50px'}} tabIndex="0"><img src="/images/product-image2-47-150x150.jpg" draggable="false" width="150" height="150"  className="" /></li>
                                                                        <li  className="slick-slide slick-active" data-slick-index="2" aria-hidden="false" style={{width: '50px'}} tabIndex="0"><img src="/images/product-image3-35-150x150.jpg" draggable="false" width="150" height="150"  className="" /></li> */}
                                                                    </div>
                                                                </div>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div  className="col col-12 col-lg-7 product-detail">


                                                    <div  className="column">
                                                        <p  className="price"><del><span  className="woocommerce-Price-amount amount"><span  className="woocommerce-Price-currencySymbol">$</span> {item.sales_price} </span></del>
                                                            <ins><span  className="woocommerce-Price-amount amount"><span  className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price}</span></ins>
                                                        </p>
                                                        <div  className="product-meta">
                                                        {item.stock > 0 ?
                                                        <div className="product-available in-stock"> In Stock</div>
                                                        : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                        }
                                                          
                                                        </div>
                                                        <div  className="woocommerce-product-details__short-description product-short-description">
                                                            <p>{item.description}</p>
                                                        </div>
                                                        

                                                        <form  className="cart single-ajax" action="#/product/all-natural-italian-style-chicken-meatballs/" method="post" encType="multipart/form-data">

                                                            <div  className="quantity">
                                                                <label  className="screen-reader-text" htmlFor="quantity_634d7d1b2b08d">{item.item_name} quantity</label>
                                                                <div onClick={() => {if(cartQuantity > 1){setCartQuantity(cartQuantity -1)}}} className="quantity-button minus"><i  className="klbth-icon-minus"></i></div>
                                                                <input onChange={()=>{}} type="text" id="quantity_634d7d1b2b08d"  className="input-text qty text" step="1" min="1" max={item.stock} name="quantity" value={cartQuantity} title="Qty" size="4" placeholder="" inputMode="numeric" />
                                                                <div onClick={() => {if(cartQuantity < item.stock){setCartQuantity(cartQuantity +1)}}} className="quantity-button plus"><i  className="klbth-icon-plus"></i></div>
                                                            </div>

                                                            <button type="button" onClick={() => handleAddToCart(item)} name="add-to-cart" value="430"  className="single_add_to_cart_button button alt">Add to cart</button>

                                                        </form>


                                                        <button  className="woosc-btn woosc-btn-430 " data-id="430" data-product_name="All Natural Italian-Style Chicken Meatballs" data-product_image="#/wp-content/uploads/2021/04/product-image-62-150x150.jpg">Compare</button>
                                                        <div  className="product-actions">
                                                            <div  className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" onClick={() => toggleWishlist(item)} data-tinvwl_product_id="430">
                                                                <div  className="tinv-wishlist-clear"></div><a role="button" tabIndex="0" aria-label="Add to Wishlist"  className={"tinvwl_add_to_wishlist_button tinvwl-icon-heart tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')} data-tinv-wl-list="{&quot;3198&quot;:{&quot;ID&quot;:3198,&quot;title&quot;:&quot;&quot;,&quot;status&quot;:&quot;share&quot;,&quot;share_key&quot;:&quot;f513c0&quot;,&quot;in&quot;:[0]}}" data-tinv-wl-product="430" data-tinv-wl-productvariation="0" data-tinv-wl-productvariations="[0]" data-tinv-wl-producttype="simple" data-tinv-wl-action="addto"><span  className="tinvwl_add_to_wishlist-text">
                                                                {(wishlistItems && wishlistItems.includes(item.id) ? ' Remove from Wishlist' : 'Add to Wishlist')} </span></a>
                                                                <div  className="tinv-wishlist-clear"></div>
                                                                <div  className="tinvwl-tooltip">Add to Wishlist</div>
                                                            </div>
                                                            {/* <button  className="woosc-btn woosc-btn-430 " data-id="430" data-product_name="All Natural Italian-Style Chicken Meatballs" data-product_image="#/wp-content/uploads/2021/04/product-image-62-150x150.jpg">Compare</button> */}
                                                        </div>
                                                        {/* <div  className="product-checklist">
                                                            <ul>
                                                                <li>Type: Organic</li>
                                                                <li>MFG: Jun 4.2021</li>
                                                                <li>LIFE: 30 days</li>
                                                            </ul>
                                                        </div> */}
                                                        <div  className="product_meta product-meta bottom">



                                                            {/* <span  className="sku_wrapper">SKU: <span  className="sku">ZU49VOR</span></span> */}


                                                            <span  className="posted_in">Category: <Link to={"/product-category/" + item?.category?.slug } rel="tag"> {item?.category?.category_name} </Link></span>
                                                            <span  className="posted_in">Item Code: <a>{item?.item_code} </a></span>
                                                            <span  className="posted_in">Barcode: <a>{item?.custom_barcode} </a></span>
                                                            {/* <span  className="tagged_as">Tags: <Link to="#/product-tag/chicken/" rel="tag">chicken</Link>, <Link to="#/product-tag/natural/" rel="tag">natural</Link>, <Link to="#/product-tag/organic/" rel="tag">organic</Link></span> */}

                                                        </div>
                                                        <div  className="product-share">
                                                            <div  className="social-share site-social style-1">
                                                                <ul  className="social-container">
                                                                    <li><Link to="#https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fproduct%2Fall-natural-italian-style-chicken-meatballs%2F"  className="facebook" target="_blank"><i  className="klbth-icon-facebook"></i></Link>
                                                                    </li>
                                                                    <li><Link to="#https://twitter.com/intent/tweet?text=All%20Natural%20Italian-Style%20Chicken%20Meatballs%20%E2%80%93%20Bacola%20%E2%80%93%20Grocery%20Market%20and%20Food%20Theme&amp;url=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fproduct%2Fall-natural-italian-style-chicken-meatballs%2F&amp;via="  className="twitter" target="_blank"><i  className="klbth-icon-twitter"></i></Link></li>
                                                                    <li><Link to="#http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fproduct%2Fall-natural-italian-style-chicken-meatballs%2F&amp;media=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwp-content%2Fuploads%2F2021%2F04%2Fbacola-logo.png&amp;description=All%20Natural%20Italian-Style%20Chicken%20Meatballs%20%E2%80%93%20Bacola%20%E2%80%93%20Grocery%20Market%20and%20Food%20Theme"  className="pinterest" target="_blank"><i  className="klbth-icon-pinterest"></i></Link>
                                                                    </li>
                                                                    <li><Link to="#http://www.linkedin.com/shareArticle?mini=true&amp;ro=true&amp;title=All%20Natural%20Italian-Style%20Chicken%20Meatballs%20%E2%80%93%20Bacola%20%E2%80%93%20Grocery%20Market%20and%20Food%20Theme&amp;url=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fproduct%2Fall-natural-italian-style-chicken-meatballs%2F&amp;summary=All%20Natural%20Italian-Style%20Chicken%20Meatballs%20%E2%80%93%20Bacola%20%E2%80%93%20Grocery%20Market%20and%20Food%20Theme&amp;source=All%20Natural%20Italian-Style%20Chicken%20Meatballs%20%E2%80%93%20Bacola%20%E2%80%93%20Grocery%20Market%20and%20Food%20Theme&amp;armin=armin"  className="linkedin" target="_blank"><i  className="klbth-icon-linkedin"></i></Link>
                                                                    </li>
                                                                    <li><Link to="#http://www.reddit.com/submit?url=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fproduct%2Fall-natural-italian-style-chicken-meatballs%2F&amp;title=All%20Natural%20Italian-Style%20Chicken%20Meatballs%20%E2%80%93%20Bacola%20%E2%80%93%20Grocery%20Market%20and%20Food%20Theme"  className="reddit" target="_blank"><i  className="klbth-icon-reddit"></i></Link></li>
                                                                    <li><Link to="#https://wa.me/?text=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fproduct%2Fall-natural-italian-style-chicken-meatballs%2F"  className="whatsapp" target="_blank"><i  className="klbth-icon-whatsapp"></i></Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div  className="column product-icons">
                                                        <div  className="alert-message">Covid-19 Info: We keep delivering.
                                                        </div>
                                                        <div  className="icon-messages">
                                                            <ul>
                                                                <li>
                                                                    <div  className="icon"><i  className="klbth-icon-delivery-truck-2"></i>
                                                                    </div>
                                                                    <div  className="message">Free Shipping apply to all
                                                                        orders
                                                                        over $100</div>
                                                                </li>
                                                                <li>
                                                                    <div  className="icon"><i  className="klbth-icon-milk-box"></i>
                                                                    </div>
                                                                    <div  className="message">Guranteed 100% Organic from
                                                                        natural
                                                                        farmas</div>
                                                                </li>
                                                                <li>
                                                                    <div  className="icon"><i  className="klbth-icon-dollar"></i>
                                                                    </div>
                                                                    <div  className="message">1 Day Returns if you change
                                                                        your
                                                                        mind</div>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {/* <div  className="woocommerce-tabs wc-tabs-wrapper">
                                        <ul  className="tabs wc-tabs" role="tablist">
                                            <li  className="description_tab active" id="tab-title-description" role="tab" aria-controls="tab-description">
                                                <Link to="#tab-description">
                                                    Description </Link>
                                            </li>
                                            <li  className="additional_information_tab" id="tab-title-additional_information" role="tab" aria-controls="tab-additional_information">
                                                <Link to="#tab-additional_information">
                                                    Additional information </Link>
                                            </li>
                                            <li  className="reviews_tab" id="tab-title-reviews" role="tab" aria-controls="tab-reviews">
                                                <Link to="#tab-reviews">
                                                    Reviews (1) </Link>
                                            </li>
                                        </ul>
                                        <div  className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab" id="tab-description" role="tabpanel" aria-labelledby="tab-title-description">

                                            <h2>Description</h2>

                                            <p>Quisque varius diam vel metus mattis, id aliquam diam rhoncus. Proin
                                                vitae
                                                magna in dui finibus malesuada et at nulla. Morbi elit ex, viverra vitae
                                                ante vel, blandit feugiat ligula. Fusce fermentum iaculis nibh, at
                                                sodales
                                                leo maximus a. Nullam ultricies sodales nunc, in pellentesque lorem
                                                mattis
                                                quis. Cras imperdiet est in nunc tristique lacinia. Nullam aliquam
                                                mauris eu
                                                accumsan tincidunt. Suspendisse velit ex, aliquet vel ornare vel,
                                                dignissim
                                                a tortor.</p>
                                            <p>Morbi ut sapien vitae odio accumsan gravida. Morbi vitae erat auctor,
                                                eleifend nunc a, lobortis neque. Praesent aliquam dignissim viverra.
                                                Maecenas lacus odio, feugiat eu nunc sit amet, maximus sagittis dolor.
                                                Vivamus nisi sapien, elementum sit amet eros sit amet, ultricies cursus
                                                ipsum. Sed consequat luctus ligula. Curabitur laoreet rhoncus blandit.
                                                Aenean vel diam ut arcu pharetra dignissim ut sed leo. Vivamus faucibus,
                                                ipsum in vestibulum vulputate, lorem orci convallis quam, sit amet
                                                consequat
                                                nulla felis pharetra lacus. Duis semper erat mauris, sed egestas purus
                                                commodo vel.</p>
                                        </div>
                                        <div  className="woocommerce-Tabs-panel woocommerce-Tabs-panel--additional_information panel entry-content wc-tab" id="tab-additional_information" role="tabpanel" aria-labelledby="tab-title-additional_information" style={{display: 'none'}}>

                                            <h2>Additional information</h2>

                                            <table  className="woocommerce-product-attributes shop_attributes">
                                                <tbody>
                                                    <tr  className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_brands">
                                                        <th  className="woocommerce-product-attributes-item__label">Brands
                                                        </th>
                                                        <td  className="woocommerce-product-attributes-item__value">
                                                            <p>Welch's</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div  className="woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab" id="tab-reviews" role="tabpanel" aria-labelledby="tab-title-reviews" style={{display: 'none'}}>
                                            <div id="reviews"  className="woocommerce-Reviews">
                                                <div id="comments">
                                                    <h2  className="woocommerce-Reviews-title">
                                                        1 review for <span>All Natural Italian-Style Chicken
                                                            Meatballs</span> </h2>

                                                    <ol  className="commentlist">
                                                        <li  className="review byuser comment-author-bacola bypostauthor even thread-even depth-1" id="li-comment-66">

                                                            <div id="comment-66"  className="comment_container">

                                                                <img alt="" src="https://secure.gravatar.com/avatar/3384f98a21c5dce2051e8f5a20928b05?s=60&amp;d=mm&amp;r=g" srcSet="https://secure.gravatar.com/avatar/3384f98a21c5dce2051e8f5a20928b05?s=120&amp;d=mm&amp;r=g 2x"  className="avatar avatar-60 photo" height="60" width="60" loading="lazy" />
                                                                <div  className="comment-text">

                                                                    <div  className="star-rating" role="img" aria-label="Rated 4 out of 5"><span style={{width:'80%'}}>Rated <strong  className="rating">4</strong> out of
                                                                            5</span>
                                                                    </div>
                                                                    <p  className="meta">
                                                                        <strong  className="woocommerce-review__author">admin
                                                                        </strong>
                                                                        <span  className="woocommerce-review__dash">–</span>
                                                                        <time  className="woocommerce-review__published-date" dateTime="2021-05-01T10:07:26+00:00">May 1,
                                                                            2021</time>
                                                                    </p>

                                                                    <div  className="description">
                                                                        <p>Sed perspiciatis unde omnis iste natus error
                                                                            sit
                                                                            voluptatem accusantium doloremque
                                                                            laudantium.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ol>

                                                </div>

                                                <div id="review_form_wrapper">
                                                    <div id="review_form">
                                                        <div id="respond"  className="comment-respond">
                                                            <span id="reply-title"  className="comment-reply-title">Add a
                                                                review
                                                                <small><a rel="nofollow" id="cancel-comment-reply-link" href="/bacola/product/all-natural-italian-style-chicken-meatballs/#respond" style={{display:'none'}}>Cancel
                                                                        reply</a></small></span>
                                                            <form action="#/wp-comments-post.php" method="post" id="commentform"  className="comment-form">
                                                                <div  className="comment-form-rating"><label htmlFor="rating">Your
                                                                        rating&nbsp;<span  className="required">*</span></label>
                                                                    <p  className="stars"> <span> <a  className="star-1" href="#">1</a> <a  className="star-2" href="#">2</a> <a  className="star-3" href="#">3</a> <a  className="star-4" href="#">4</a> <a  className="star-5" href="#">5</a> </span> </p><select name="rating" id="rating" required="" style={{display: 'none'}}>
                                                                        <option value="">Rate…</option>
                                                                        <option value="5">Perfect</option>
                                                                        <option value="4">Good</option>
                                                                        <option value="3">Average</option>
                                                                        <option value="2">Not that bad</option>
                                                                        <option value="1">Very poor</option>
                                                                    </select>
                                                                </div>
                                                                <p  className="comment-form-comment"><label htmlFor="comment">Your
                                                                        review&nbsp;<span  className="required">*</span></label><textarea id="comment" name="comment" cols="45" rows="8" required=""></textarea></p>
                                                                <p  className="form-submit"><input onChange={()=>{}} name="submit" type="submit" id="submit"  className="submit" value="Submit" /> <input onChange={()=>{}} type="hidden" name="comment_post_ID" value="430" id="comment_post_ID" />
                                                                    <input onChange={()=>{}} type="hidden" name="comment_parent" id="comment_parent" value="0" />
                                                                </p>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div  className="clear"></div>
                                            </div>
                                        </div>

                                    </div> */}

                                    {item?.id ? <RelatedProducts item_id={item.id} category_id={item.category_id} /> : null}


                                    {/* <div  className="single-sticky-titles">
                                        <div  className="container">
                                            <Link to="#tab-description">
                                                Description </Link>

                                            <Link to="#tab-additional_information">
                                                Additional information </Link>

                                            <Link to="#tab-reviews">
                                                Reviews (1) </Link>
                                            <Link to="#related-products"  className="no-tab">Related products</Link>
                                        </div>
                                    </div> */}


                                </div>
                            </div>
                        </div>

                        <RecentViewProducts prod_id={params.id} />
                    </div>
                </div>
        </div>
        </main>
    );
}

export default Product;