export const isLogin = () => {  
    let token = localStorage.getItem('token');
    let is_authenticated = localStorage.getItem('is_authenticated');
    
    if((!token) || (!is_authenticated) || is_authenticated === '0'){
        return false;
    }
    return true;
}

export const randomString = (length) =>  {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const convertToSlug = (str) =>  {
    return str.toLowerCase()
               .replace(/ /g, '-')
               .replace(/[^\w-]+/g, '');
  }